import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Percentage from '../../assets/Percentage.svg';
import { ReactComponent as RadioIcon } from '../../assets/RadioIcon.svg';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import { CustomPaper, StyledDivider3 } from '../../components/common/style';

import {
  ButtonWrapper,
  CommonWrapper,
  LabelDescription,
  PageTitle,
  PageWrapper,
  TitleWrapper,
} from './PermissionStyle';
// import BANKLIST from "../../_mockData_/BankList";
import { LoadingButton } from '@mui/lab';
import GetAxiosInstance from '../../components/common/api';
import { getUserData } from '../../components/common/common';
import { ACCOUNT_TYPES } from '../../util/accountTypes';
import banksList from '../../util/bankList';
import {
  DirectDebitCard,
  TopCardDebitListWrapper,
  TopCardListItem,
} from '../dashboard-ui/DashboardUIStyle';
import { SubCardTopInfo } from '../dashboard/DashboardStyle';
import ConfirmPayment from './ConfirmPayment';

const ExpandMore02 = styled((props) => {
  const { expand02, ...other } = props;
  return <IconButton {...other} expand={expand02?.toString()} />;
})(({ theme, expand }) => ({
  transform: expand === 'true' ? 'rotate(0deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DirectDebit = () => {
  const { id } = useParams();
  const theme = useTheme();
  const { externalAppConsentId } = useParams();
  const [data, setData] = useState();

  const [userData, setUserData] = useState();
  const { state } = useLocation();
  useEffect(() => {
    let userData = getUserData();
    setUserData(userData);
    sessionStorage.setItem('eDoc:ExternalAppConsentId', externalAppConsentId);
  }, []);
  const [accountDetails, setAccountDetails] = useState('');
  const [bankList, setBankList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [expanded02, setExpanded02] = useState(true);

  const [selectedBankLogo, setSelectedBankLogo] = useState('');

  const [accountNumberError, setAccountNumberError] = useState('');
  const [bankSelectError, setBankSelectError] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetAxiosInstance(
          `orchestrator/mandate-template/${id}`,
          'post',
          {
            email: state?.email,
          },
          {
            'journey-type': 'Mandate',
          }
        );
        if (res?.status === 200) {
          setData(res.data.data?.template);
        }
        const bankData = await banksList({
          'journey-type': 'Mandate',
        });
        const findWemaBank = bankData.find(
          (x) => x.bankName === process.env.REACT_APP_WEMA_KEY
        );
        const findProvidusBank = bankData.find(
          (x) => x.bankName === process.env.REACT_APP_PROVIDUS_KEY
        );
        setBankList([
          { ...findWemaBank, bankName: 'wema' },
          { ...findProvidusBank, bankName: 'providus' },
        ]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id, state?.email]);

  const handleExpandClick02 = () => {
    setExpanded02(!expanded02);
  };
  const handleAllow = (e) => {
    setLoader(true);
    let accountNumberError = '';
    let bankSelectError = '';
    if (!accountDetails?.number) {
      accountNumberError = 'Please Enter Account Number';
    } else {
      accountNumberError = '';
    }
    if (!accountDetails?.bankName) {
      bankSelectError = 'Please select Bank';
    } else {
      bankSelectError = '';
    }

    setBankSelectError(bankSelectError);
    setAccountNumberError(accountNumberError);
    if (accountNumberError || bankSelectError) {
      setLoader(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <PageWrapper>
        <TitleWrapper>
          <PageTitle>
            Setup a Direct Debit with {data?.businessPartnerName}
          </PageTitle>
        </TitleWrapper>

        <CommonWrapper>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="Body_medium_20" color="orange.main">
              Products
            </Typography>
            <ExpandMore02
              expand={expanded02}
              onClick={handleExpandClick02}
              aria-expanded={expanded02}
              aria-label="show more"
            >
              {expanded02 ? (
                <RemoveIcon sx={{ fill: theme.palette.orange.main }} />
              ) : (
                <AddIcon sx={{ fill: theme.palette.orange.main }} />
              )}
            </ExpandMore02>
          </CardContent>

          <Divider sx={{ borderColor: theme.palette.primary.lighter }} />

          <Collapse in={expanded02}>
            <CardContent
              sx={{
                paddingTop: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {' '}
              <Grid container spacing={1}>
                <Grid item xs={12} md={7}>
                  <DirectDebitCard>
                    <SubCardTopInfo>
                      <Typography
                        variant="Body_medium_20"
                        color="orange.main"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          spacing: 2,
                        }}
                      >
                        <Box
                          component="img"
                          src={Percentage}
                          alt="Percentage"
                        />
                        <LabelDescription color="grey.darker" ml={1.5}>
                          Direct Debit
                        </LabelDescription>
                      </Typography>
                      <RadioIcon />
                    </SubCardTopInfo>
                    <StyledDivider3 />
                    <Stack
                      direction="row"
                      alignItems="center"
                      marginTop="0"
                      justifyContent="space-between"
                    >
                      <TopCardDebitListWrapper>
                        <TopCardListItem>
                          <Typography
                            variant="Body_medium_14"
                            color="grey.light"
                          >
                            Reason for payment
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography
                            variant="Body_medium_14"
                            color="grey.light"
                          >
                            Frequency
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography
                            variant="Body_medium_14"
                            color="grey.light"
                          >
                            No of times
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography
                            variant="Body_medium_14"
                            color="grey.light"
                          >
                            Amount to be paid today
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography
                            variant="Body_medium_14"
                            color="grey.light"
                          >
                            Direct debit amount
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography
                            variant="Body_medium_14"
                            color="grey.light"
                          >
                            Total amount
                          </Typography>
                        </TopCardListItem>
                      </TopCardDebitListWrapper>
                      <TopCardDebitListWrapper alignItems={'flex-end'}>
                        <TopCardListItem>
                          <Typography variant="Body_medium_14">
                            {data?.reasonForPayment}
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography variant="Body_medium_14">
                            {data?.frequency}
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography variant="Body_medium_14">
                            {data?.paymentCount}
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography variant="Body_medium_14">
                            ₦ {data?.instantDebitAmount}
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography variant="Body_medium_14">
                            ₦ {data?.amount}
                          </Typography>
                        </TopCardListItem>
                        <TopCardListItem>
                          <Typography variant="Body_medium_14">
                            ₦{' '}
                            {parseInt(data?.amount || 0) *
                              parseInt(data?.paymentCount || 0)}
                          </Typography>
                        </TopCardListItem>
                      </TopCardDebitListWrapper>
                    </Stack>
                  </DirectDebitCard>
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
          {/* )}        */}
          <Box sx={{ width: '100%' }}>
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Grid container gap={4} alignItems="flex-start">
                <Grid item md={7} xs={12}>
                  <Typography
                    className="account-information-label"
                    variant="Body_medium_16"
                    color="orange.main"
                  >
                    Account Number
                  </Typography>
                  <TextField
                    id="filled-hidden-label-normal"
                    hiddenLabel
                    inputProps={{ 'aria-label': 'Without label' }}
                    fullWidth
                    variant="filled"
                    placeholder="Enter your account number"
                    autoComplete="off"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={accountDetails?.number || ''}
                    helperText={accountNumberError && accountNumberError}
                    onChange={(e) => {
                      setAccountDetails({
                        ...accountDetails,
                        number: e.target.value,
                      });
                    }}
                  />

                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                    mt={2}
                  ></Stack>
                </Grid>

                <Grid item md={4} xs={12}>
                  <Typography
                    className="account-information-label"
                    variant="Body_medium_16"
                    color="orange.main"
                  >
                    Bank
                  </Typography>
                  <Autocomplete
                    fullWidth
                    id="select-product"
                    options={bankList}
                    disableClearable
                    getOptionDisabled={(option) => !option?.enabled}
                    getOptionLabel={(option) => option.displayName}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.bankName}
                        name={option.bankName}
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          name={option.bankName}
                          width="48"
                          style={{ borderRadius: '50%' }}
                          src={option.icon}
                          alt=""
                        />
                        {option.displayName}
                      </Box>
                    )}
                    PaperComponent={CustomPaper}
                    onChange={(e) => {
                      setAccountDetails({
                        ...accountDetails,
                        bankName: e.target.getAttribute('name'),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="filled"
                        id="filled-hidden-label-normal"
                        placeholder="Select..."
                        hiddenLabel
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {selectedBankLogo && (
                                <Box
                                  component="img"
                                  src={selectedBankLogo}
                                  alt=""
                                  sx={{
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                    )}
                  />
                  <Box
                    component={'div'}
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      fontSize: '.75rem',
                      lineHeight: '1.66',
                      textAlign: 'left',
                      margin: '3px 14px 0 14px',
                      color: 'red',
                    }}
                  >
                    {bankSelectError && bankSelectError}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Box>

          <ButtonWrapper>
            <Box sx={{ width: 'inherit' }}>
              <AnimateButton>
                <Button
                  sx={{ width: { xs: '-webkit-fill-available', md: '215px' } }}
                  size="large"
                  fullWidth
                  variant="outlined"
                  //   onClick={handleCloseDialog}
                >
                  Back
                </Button>
              </AnimateButton>
            </Box>

            <Box sx={{ width: 'inherit' }}>
              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="contained"
                  sx={{ width: { xs: '-webkit-fill-available', md: '215px' } }}
                  onClick={handleAllow}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loader}
                >
                  Allow
                </LoadingButton>
              </AnimateButton>
            </Box>
          </ButtonWrapper>

          <ConfirmPayment
            isOpen={isOpen}
            data={data}
            accountDetails={accountDetails}
            onClose={() => setIsOpen(false)} // Correctly defining onClose as a function
          />
        </CommonWrapper>
      </PageWrapper>
    </>
  );
};

export default DirectDebit;