import GetAxiosInstance from '../components/common/api';
export default async function banksList(journeyType) {
  // const getCatchData = JSON.parse(localStorage.getItem('bankList'));
  // if (!getCatchData) {
  return new Promise(async (resolve, reject) => {
    await GetAxiosInstance(
      'orchestrator/get-banks',
      'get',
      {},
      journeyType
    ).then((res, err) => {
      if (res) {
        // localStorage.setItem('bankList', JSON.stringify(res.data.data));
        resolve(res.data.data || []);
      } else {
        reject([]);
      }
    });
  });
  // } else {
  //   return getCatchData;
  // }
}
