import {
  Box,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import { BoxWrapper } from '../../components/common/style';
import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav';
import {
  StyledTableCellData,
  StyledTableCellDataItem,
  TableCellHead,
} from '../category-insights/CategoryInsightsStyle';

function createData(Item, Percentage, Amount) {
  return { Item, Percentage, Amount };
}
const rows = [
  createData('Restaurant', '23%', '₦ 300,000.00'),
  createData('Telco', '10%', '₦ 180,000.00'),
  createData('Gambling', '10%', '₦ 180,000.00'),
  createData('Overdraft', '10%', '₦ 180,000.00'),
  createData('Loans', '10%', '₦ 180,000.00'),
  createData('Gas', '10%', '₦ 180,000.00'),
  createData('Nightlife', '10%', '₦ 180,000.00'),
  createData('Parties', '10%', '₦ 180,000.00'),
  createData('Hangouts', '10%', '₦ 180,000.00'),
  createData('Trips', '10%', '₦ 180,000.00'),
];

const monthRange = [
  { title: '1 Month', value: 1 },
  {
    title: '3 Months',
    value: 3,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '12 Months',
    value: 12,
  },
];
const accountIds = [
  {
    title: '0235087159',
    value: null,
  },
  {
    title: '02350871589',
    value: null,
  },
];
const typeOptions = [
  {
    title: 'Total',
    value: 'tran_amount_total_for_period',
  },
  {
    title: 'Monthly Average',
    value: 'tran_amount_monthly_average',
  },
];

const mlOptions = [
  {
    title: 'Text Based',
    value: false,
  },
  {
    title: 'Chat GPT',
    value: true,
  },
];
const BVN = () => {
  const theme = useTheme();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setLoader(true);
    if (location.state?.bvnInfo?.length) {
      setLoader(false);
      setData(location.state?.bvnInfo);
    } else {
      setLoader(false);
    }
  }, [location]);
  const maskAccountNumber = (accountNumber) => {
    if (!accountNumber) return '';
    const lastFourDigits = accountNumber.slice(-4);
    const maskedPart = accountNumber.slice(0, -4).replace(/./g, '*');
    return maskedPart + lastFourDigits;
  };
  return (
    <>
      <DashboardUINav product="bvn" />
      <BoxWrapper mt={5}>
        <Box>
          <Typography
            variant="Body_semibold_32"
            sx={{
              fontWeight: 'bold',
            }}
          >
            Account Lookup
          </Typography>
          <Typography mt={2}>
            BVN Number: {location?.state?.bvnNumber}
          </Typography>
          <Typography mt={2} mb={3}>
            {data?.length} Total Accounts
          </Typography>
        </Box>
        <TableContainer>
          <Table
            stickyHeader
            sx={{
              minWidth: 350,
            }}
          >
            {loader ? (
              <Loader />
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <TableCellHead>Account Name</TableCellHead>
                    <TableCellHead>Bank</TableCellHead>
                    <TableCellHead>Account Number</TableCellHead>
                    <TableCellHead>Account Type</TableCellHead>
                    <TableCellHead>Status</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow hover key={index}>
                      <StyledTableCellDataItem>
                        {row.accountname}
                      </StyledTableCellDataItem>
                      <StyledTableCellData>{row?.BankName}</StyledTableCellData>
                      <StyledTableCellData>
                        {maskAccountNumber(row?.accountnumber)}
                      </StyledTableCellData>
                      <StyledTableCellData>
                        {row?.accounttype === '1' ? 'Person' : 'Business'}
                      </StyledTableCellData>
                      <StyledTableCellData status={row?.accountstatus}>
                        {row?.accountstatus === '1' ? (
                          <Stack color={'#3FEA8E'}>Active</Stack>
                        ) : (
                          <Stack color={'#FE4443'}>De Active</Stack>
                        )}
                      </StyledTableCellData>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </BoxWrapper>
    </>
  );
};

export default BVN;
