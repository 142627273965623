import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

export default function SessionExpiredDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>Your session has expired. Please log in again.</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Login Again
        </Button>
      </DialogActions>
    </Dialog>
  );
}
