import { LoadingButton } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import Error from '../../assets/error.png';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TimerIcon } from '../../assets/timer.svg';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import { StyledDivider3 } from '../../components/common/style';
import {
  ConfirmPaymentCard,
  ReviewDetails,
  ReviewDetailsCard,
  ReviewListItem,
  TopCardDebitListWrapper,
  TopCardListItem,
} from '../dashboard-ui/DashboardUIStyle';
import { SubCardTopInfo, SubCardWeapper } from '../dashboard/DashboardStyle';
import GetAxiosInstance from '../../components/common/api';
import { useNavigate } from 'react-router-dom';

const ConfirmPayment = ({ isOpen, onClose, data, accountDetails }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState();
  const [verifyLoader, setVerifyLoader] = useState();
  const [mandateId, setMandateId] = useState();
  const theme = useTheme();
  const [isPendingView, setIsPendingView] = useState();
  const handleAllow = () => {
    setLoader(true);
    const payload = {
      mandateTemplateId: data.templateId,
      accountNumber: accountDetails.number,
      bankName: accountDetails.bankName,
    };
    GetAxiosInstance(`orchestrator/mandate/initiate`, 'post', payload, {
      'journey-type': 'Mandate',
    }).then((res) => {
      if (res?.status === 200) {
        setMandateId(res.data.data.manadateId);
        setIsPendingView(true);
        setLoader(false);
        onClose();
      } else {
        setLoader(false);
        toast.error(res.response.data.message);
      }
    });
  };

  const PageDescriptionSpan = styled('span')(({ theme }) => ({
    ...theme.typography.Body_light_16,
    color: theme.palette.grey.darker,
    display: 'inline-block',

    [theme.breakpoints.down('sm')]: {
      ...theme.typography.Body_light_14,
    },
  }));

  const StepDescription = styled(Typography)(({ theme }) => ({
    ...theme.typography.Body_light_16,
    color: theme.palette.grey[99],
    display: 'inline-block',

    [theme.breakpoints.down('sm')]: {
      ...theme.typography.Body_light_14,
    },
  }));
  const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    '& .MuiStepLabel-iconContainer': {
      paddingRight: theme.spacing(2),
    },
    '& .MuiStepIcon-root': {
      fill: theme.palette.orange.main,
    },
  }));

  const steps = [
    <StepDescription>
      Login to your <PageDescriptionSpan> Wema bank </PageDescriptionSpan> app
      to approve the request before you can view the dashboard.{' '}
    </StepDescription>,
    <StepDescription>
      Click on <PageDescriptionSpan>Profile</PageDescriptionSpan> {'>'}{' '}
      <PageDescriptionSpan>Authenticator {'>'} </PageDescriptionSpan>{' '}
      <PageDescriptionSpan>Pending Authentications</PageDescriptionSpan>
    </StepDescription>,
    <StepDescription>
      Approve the request then click on the{' '}
      <PageDescriptionSpan>"View Dashboard"</PageDescriptionSpan> button below.
    </StepDescription>,
    <StepDescription>
      The request will timeout in{' '}
      <PageDescriptionSpan>10 minutes</PageDescriptionSpan>.
    </StepDescription>,
  ];

  const CustomStepConnector = styled('div')(({ theme }) => ({
    width: 2,
    height: '80%',
    backgroundColor: theme.palette.orange.main,
    position: 'absolute',
    left: 11,
    top: 24,
  }));

  const PageTitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.Header_semibold_32,

    [theme.breakpoints.down('sm')]: {
      ...theme.typography.Body_semibold_20,
    },
  }));
  const handleClick = () => {
    setVerifyLoader(true);
    const payload = {
      mandateId: mandateId,
      verificationType: 'BankMandateApp',
    };
    GetAxiosInstance(`orchestrator/mandate/verify`, 'post', payload, {
      'journey-type': 'Mandate',
    }).then((res) => {
      if (res?.status === 200) {
        setVerifyLoader(false);
        navigate('/direct-success');
      } else {
        toast.error(res.response.data.message);
        setVerifyLoader(false);
      }
    });
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '450px  ',
            borderRadius: '12px',
            bgcolor: '#001823',
            p: 4,
          },
        }}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems={'center'}
            gap={2}
            mb={2}
          >
            <Box component={'img'} src={Error} />
            <Typography variant="Body_semibold_24">Confirm Payment</Typography>

            <Typography
              textAlign={'center'}
              variant="Body_medium_14"
              color="grey.light"
            >
              Review details of this transaction and hit confirm to proceed
            </Typography>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ReviewDetailsCard>
                <ReviewDetails>
                  {data?.schedule?.length ? (
                    <>
                      {data?.schedule.map((item, index) => (
                        <ReviewListItem>
                          <Typography
                            variant="Body_medium_14"
                            color="grey.light"
                          >
                            {index + 1} Payment
                          </Typography>
                          <Typography
                            variant="Body_medium_14"
                            color="grey.darker"
                          >
                            {moment(item).format('Do MMMM, YYYY')}
                          </Typography>
                        </ReviewListItem>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </ReviewDetails>
              </ReviewDetailsCard>
            </Grid>

            <Grid item xs={12}>
              <ConfirmPaymentCard>
                <SubCardWeapper>
                  <SubCardTopInfo>
                    <TopCardDebitListWrapper>
                      <TopCardListItem>
                        <Typography variant="Body_medium_14" color="grey.light">
                          Tax.
                        </Typography>
                      </TopCardListItem>
                      <TopCardListItem>
                        <Typography variant="Body_medium_14" color="grey.light">
                          Total Amount
                        </Typography>
                      </TopCardListItem>
                    </TopCardDebitListWrapper>
                    <TopCardDebitListWrapper sx={{ alignItems: 'flex-end' }}>
                      <TopCardListItem>
                        <Typography variant="Body_medium_14">₦ 0</Typography>
                      </TopCardListItem>
                      <TopCardListItem>
                        <Typography variant="Body_medium_14">
                          ₦{' '}
                          {parseInt(data?.amount || 0) *
                            parseInt(data?.paymentCount || 0)}
                        </Typography>
                      </TopCardListItem>
                    </TopCardDebitListWrapper>
                  </SubCardTopInfo>
                  <StyledDivider3 />
                  <Stack
                    direction="row"
                    alignItems="center"
                    marginTop="0"
                    justifyContent="space-between"
                  >
                    <TopCardDebitListWrapper>
                      <TopCardListItem>
                        <Typography variant="Body_medium_14" color="grey.light">
                          Amount to Pay
                        </Typography>
                      </TopCardListItem>
                    </TopCardDebitListWrapper>
                    <TopCardDebitListWrapper alignItems={'flex-end'}>
                      <TopCardListItem>
                        <Typography
                          variant="Body_medium_16"
                          color="orange.main"
                        >
                          ₦{' '}
                          {parseInt(data?.amount || 0) }
                        </Typography>
                      </TopCardListItem>
                    </TopCardDebitListWrapper>
                  </Stack>
                </SubCardWeapper>
              </ConfirmPaymentCard>
            </Grid>
          </Grid>

          <Stack
            mt={6}
            direction="row"
            alignItems="center"
            spacing={4}
            justifyContent="space-between"
          >
            <Button
              sx={{ width: '-webkit-fill-available' }}
              size="large"
              // component={RouterLink}
              to="/view-plans"
              type="submit"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            {/* <AnimateButton> */}
            <LoadingButton
              size="large"
              type="button"
              variant="contained"
              sx={{ width: '-webkit-fill-available' }}
              loadingIndicator={
                <CircularProgress
                  style={{
                    padding: '60px',
                    color: theme.palette.orange.main,
                  }}
                />
              }
              loading={loader}
              onClick={handleAllow}
            >
              Allow
            </LoadingButton>
            {/* </AnimateButton> */}
          </Stack>
        </Box>
      </Dialog>
      <Dialog
        open={isPendingView}
        keepMounted
        className="white-bg-container"
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
            boxShadow: 'none',
            borderRadius: '12px',
            overflow: 'hidden',
            padding: theme.spacing(3),
            maxWidth: 480,
            width: '100%',
          },
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Stack
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={1.5}
          >
            <TimerIcon />
            <PageTitle>Pending Approval</PageTitle>

            {/* <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <WatchIcon />
              <Typography
                variant="Body_medium_14"
                sx={{ color: theme.palette.grey[99] }}
              >
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
            </Stack> */}
          </Stack>

          <Stepper
            activeStep={0}
            orientation="vertical"
            connector={<CustomStepConnector />}
            sx={{ width: '100%', mt: 2, position: 'relative' }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <CustomStepLabel>{label}</CustomStepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid container>
            <Grid item xs={12}>
              <AnimateButton>
                <LoadingButton
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{
                    marginTop: '15px',
                    minWidth: '100%',
                  }}
                  onClick={handleClick}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={verifyLoader}
                >
                  {'Verify'}
                </LoadingButton>
              </AnimateButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmPayment;
