import React from 'react';
import SuccessModal from '../../components/common/SuccessModal';
import { useNavigate } from 'react-router-dom';

const IncomeVerificationVerifySuccess = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/login');
  };
  return (
    <>
      <SuccessModal
        successMsg="Income verification"
        success="Completed!"
        subTitle="Thank you for completing the process! You will shortly hear from your
Potential employer"
        btnName="Proceed"
        handleClick={handleClick}
      />
    </>
  );
};

export default IncomeVerificationVerifySuccess;
