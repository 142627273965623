import React, { useState, useEffect } from 'react';
import {
  Stack,
  TextField,
  Grid,
  Typography,
  Box,
  CircularProgress,
  useTheme,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GetAxiosInstance from '../../components/common/api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import { CustomPaper } from '../../components/common/style';
import { ContentStyle, IncomeDetailsStyle, SignupBg } from '../Auth/AuthStyle';
import banksList from '../../util/bankList';

import axios from 'axios';

// Form validation schema
const formSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .required('Account number is required')
    .matches(/^\d+$/, 'Account number must be numeric'),
  statementDuration: yup
    .number()
    .required('Statement duration is required')
    .min(1, 'Duration must be at least 1 month')
    .max(60, 'Duration cannot exceed 60 months'),
  accountType: yup.string().required('Account type is required'),
});

export function IncomeVerificationDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [bankSelectError, setBankSelectError] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const fetchBankList = async () => {
      const banks = await banksList({
        'journey-type': 'income-verification',
      });
      setBankList(banks);
    };
    fetchBankList();
  }, []);

  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleBankChange = (event, value) => {
    if (value) {
      setSelectedBank(value);
      setBankSelectError('');
    } else {
      setSelectedBank(null);
      setBankSelectError('Please select a bank');
    }
  };

  const handleSubmit = async () => {
    if (!selectedBank) {
      setBankSelectError('Please select a bank');
      return;
    }

    const formData = getValues(); // Get form data manually
    const payload = {
      bankName: selectedBank.bankName,
      accountNumber: formData.accountNumber,
      statementDuration: 12,
      accountType: 'personal',
    };

    setLoading(true);

    attachAccount(payload);
  };

  const attachAccount = async (payload) => {
    setLoading(true);

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_EDOC_API_URL,
      headers: {
        Authorization: `Bearer ${location?.state?.user?.accessToken}`,
      },
    });

    try {
      const response = await axiosInstance.post(
        `orchestrator/incomeVerification/attachAccount/${id}`,
        payload,
        {
          'journey-type': 'income-verification',
        }
      );

      setLoading(false);
      if (response?.data?.codes === 200) {
        toast.success('Account successfully attached');
        navigate(`/income-verification/partner/otp/${id}`, {
          state: location.state,
        });
      } else {
        toast.error(response?.data?.message || 'Something went wrong');
      }
    } catch (error) {
      // Handle error
      setLoading(false);
      console.error('API Error:', error);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <SignupBg>
      <ContentStyle sx={{ minHeight: '100vh' }}>
        <IncomeDetailsStyle>
          <Typography variant="Body_semibold_24">
            Please fill in the details
          </Typography>

          <Grid container spacing={4} mt={1} mb={3} alignItems="center">
            {/* Account Number */}
            <Grid item xs={12}>
              <Typography
                className="account-information-label"
                variant="Body_medium_16"
                color="orange.main"
              >
                Account Number
              </Typography>
              <TextField
                variant="filled"
                type="text"
                placeholder="Enter Account Number"
                fullWidth
                {...register('accountNumber')}
                error={!!errors.accountNumber}
                helperText={errors.accountNumber?.message}
              />
            </Grid>

            {/* Bank Name */}
            <Grid item xs={12}>
              <Typography
                className="account-information-label"
                variant="Body_medium_16"
                color="orange.main"
              >
                Bank Name
              </Typography>
              <Autocomplete
                id="select-bank"
                options={bankList}
                getOptionLabel={(option) => option.displayName}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option.bankName}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  >
                    <img
                      loading="lazy"
                      src={option.icon}
                      alt=""
                      width="48"
                      style={{ borderRadius: '50%' }}
                    />
                    {option.displayName}
                  </Box>
                )}
                PaperComponent={CustomPaper}
                onChange={handleBankChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder="Select..."
                    hiddenLabel
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {selectedBank?.icon && (
                            <Box
                              component="img"
                              src={selectedBank.icon}
                              alt=""
                              sx={{
                                width: '48px',
                                height: '48px',
                                borderRadius: '50%',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Box
                style={{
                  color: 'red',
                  fontSize: '0.75rem',
                  margin: '3px 14px 0 14px',
                }}
              >
                {bankSelectError}
              </Box>
            </Grid>
          </Grid>

          {/* Submit Button */}
          <AnimateButton>
            <LoadingButton
              size="large"
              type="submit" // Triggers the form submission
              onClick={handleSubmit} // Bind handleSubmit here
              variant="contained"
              style={{ width: '100%' }}
              loadingIndicator={
                <CircularProgress
                  style={{
                    padding: '60px',
                    color: theme.palette.orange.main,
                  }}
                />
              }
              loading={loading}
            >
              Proceed
            </LoadingButton>
          </AnimateButton>
        </IncomeDetailsStyle>
      </ContentStyle>
    </SignupBg>
  );
}