import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  ContentStyle,
  FormStyle,
  FromWrapper,
  RootStyle,
} from '../../pages/Auth/AuthStyle';

import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import { ButtonWrapper } from './PermissionStyle';
import { useTheme } from '@emotion/react';
import GetAxiosInstance from '../../components/common/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const BVNWaiting = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const viewDashboard = () => {
    setLoader(true);
    if (location?.state?.bvnConsentId)
      GetAxiosInstance(
        'orchestrator/bvn/verify-consent',
        'post',
        {
          bvnConsentId: location?.state?.bvnConsentId,
        },
        {
          'journey-type': 'BVN',
        }
      )
        .then((res) => {
          if (res?.data?.codes === 200) {
            navigate('/bvn-dashboard', {
              state: {
                ...res?.data?.data,
                bvnNumber: location?.state?.bvnNumber,
              },
            });
          } else {
            setLoader(false);
          }
        })
        .catch(function (error) {
          setLoader(false);
          toast.error('');
        });
  };
  return (
    // <RootStyle>
    <FromWrapper>
      <ContentStyle>
        <FormStyle component="box">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography fontSize={32} variant="Body_semi_32" mb={3}>
              {' '}
              Important
            </Typography>
            <Box fontSize={16} textAlign="center">
              You will be directed to the NIBBSS consent page to complete the
              process. Please make sure to complete the process as soon as
              possible. The process will expire in 5mins
              <br />
              {props.success}
            </Box>
          </Box>
          <ButtonWrapper>
            <Box sx={{ width: 'inherit' }}>
              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="contained"
                  sx={{
                    width: { xs: '-webkit-fill-available', md: '415px' },
                  }}
                  onClick={viewDashboard}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loader}
                >
                  View Dashboard
                </LoadingButton>
              </AnimateButton>
            </Box>
          </ButtonWrapper>
        </FormStyle>
      </ContentStyle>
    </FromWrapper>
    // </RootStyle>
  );
};

export default BVNWaiting;
