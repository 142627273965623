import {
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Grid,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GetAxiosInstance from "../../components/common/api";
import { getUserData } from "../../components/common/common";
import AnimateButton from "../../components/common/formStyle/AnimateButton";

import { ReactComponent as TimerIcon } from "../../assets/timer.svg";
import { ReactComponent as WatchIcon } from "../../assets/watch.svg";

const PageTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Header_semibold_32,

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_semibold_20,
  },
}));

const StepDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_16,
  color: theme.palette.grey[99],
  display: "inline-block",

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_light_14,
  },
}));

const PageDescriptionSpan = styled("span")(({ theme }) => ({
  ...theme.typography.Body_light_16,
  color: theme.palette.grey.darker,
  display: "inline-block",

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_light_14,
  },
}));

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& .MuiStepLabel-iconContainer": {
    paddingRight: theme.spacing(2),
  },
  "& .MuiStepIcon-root": {
    fill: theme.palette.orange.main,
  },
}));

const CustomStepConnector = styled("div")(({ theme }) => ({
  width: 2,
  height: "80%",
  backgroundColor: theme.palette.orange.main,
  position: "absolute",
  left: 11,
  top: 24,
}));

const PendingApproval = () => {
  const theme = useTheme();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [otpExpiryDate, setOtpExpiryDate] = useState();
  const [timeDiff, setTimeDiff] = useState(0);

  useEffect(() => {
    setOtpExpiryDate(moment(state?.bankReferenceExpiry));
  }, [state]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const updatedCurrentDate = moment();
      const updatedTimeDiff = otpExpiryDate.diff(updatedCurrentDate);
      setTimeDiff(updatedTimeDiff);
      if (updatedTimeDiff <= 0) {
        clearInterval(timerInterval);
        navigate("/account-information");
        toast.error("OTP has been expired.");
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [otpExpiryDate, navigate]);

  const remainingTime = moment.duration(timeDiff);
  const minutes = remainingTime.minutes();
  const seconds = remainingTime.seconds();
  const [externalAppConsentId, setExternalAppConsentId] = useState();
  const [loader, setLoader] = useState();

  if (!state?.consentId) {
    navigate("/home");
  }

  const payload = { consentId: state?.consentId };

    useEffect(() => {
      const sessionManage = () => {
        let userData = getUserData();
        setUserData(userData);
        sessionStorage.setItem(
          'eDoc:ExternalAppConsentId',
          externalAppConsentId
        );
      };
      sessionManage();
    }, []);

    const handleClick = () => {
      setLoader(true);
      GetAxiosInstance('orchestrator/get-transactions', 'post', payload, {
        ...userData.accessToken,
        'journey-type': 'Dashboard',
      }).then((res) => {
        if (res?.data?.codes === 200) {
          setLoader(false);
          let postObj = {
            journey: 'CONSENT',
            eventName: 'CONSENT.TRANSACTIONS',
            status: 'SUCCESS',
            uniqueIdentifier: payload?.consentId,
            subIdentifier: state?.bankName,
            data: { req: payload, res: res?.data?.data },
          };
          if (userData && userData?.userType === 'nonGuest') {
            postObj['source'] = 'CONNECT';

            // Check if any product has the name "KYC"
            const hasKYCProduct = res.data?.data?.products?.some(
              (product) => product.name === 'KYC'
            );

            // Redirect based on the product name
            const redirectPath = hasKYCProduct
              ? '/income-and-job-verification'
              : '/dashboard';

            navigate(redirectPath, {
              state: {
                ...res.data?.data,
                bankName: process.env.REACT_APP_WEMA_KEY,
              },
            });
          } else if (externalAppConsentId) {
            postObj['source'] = 'EXTERNAL_APP';
            navigate(
              `/consent-request/${externalAppConsentId}/consent-success`,
              {
                state: { ...res.data?.data },
              }
            );
          } else {
            postObj['source'] = 'BUSINESS_PARTNER';
            navigate('/consent-success');
          }
          GetAxiosInstance(`log/eventLog`, 'post', postObj);
        } else {
          let postObj = {
            journey: 'CONSENT',
            eventName: 'CONSENT.TRANSACTIONS',
            status: 'ERROR',
            uniqueIdentifier: payload?.consentId,
            subIdentifier: state?.bankName,
            data: { req: payload, res: res?.data?.message },
          };
          if (externalAppConsentId) {
            postObj['source'] = 'EXTERNAL_APP';
          } else if (userData && userData?.userType !== 'nonGuest') {
            postObj['source'] = 'BUSINESS_PARTNER';
          } else {
            postObj['source'] = 'CONNECT';
          }
          GetAxiosInstance(`log/eventLog`, 'post', postObj);
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      }).catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.message || err.message);
      } );
    };

  const steps = [
    <StepDescription>
      Login to your <PageDescriptionSpan> Wema bank </PageDescriptionSpan> app
      to approve the request before you can view the dashboard.{" "}
    </StepDescription>,
    <StepDescription>
      Click on <PageDescriptionSpan>Profile</PageDescriptionSpan> {">"}{" "}
      <PageDescriptionSpan>Authenticator {">"} </PageDescriptionSpan>{" "}
      <PageDescriptionSpan>Pending Authentications</PageDescriptionSpan>
    </StepDescription>,
    <StepDescription>
      Approve the request then click on the{" "}
      <PageDescriptionSpan>"View Dashboard"</PageDescriptionSpan> button below.
    </StepDescription>,
    <StepDescription>
      The request will timeout in{" "}
      <PageDescriptionSpan>10 minutes</PageDescriptionSpan>.
    </StepDescription>,
  ];

  return (
    <Box component={"div"}>
      <Dialog
        open={true}
        keepMounted
        className="white-bg-container"
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
            boxShadow: "none",
            borderRadius: "12px",
            overflow: "hidden",
            padding: theme.spacing(3),
            maxWidth: 480,
            width: "100%",
          },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1.5}
          >
            <TimerIcon />
            <PageTitle>Pending Approval</PageTitle>

            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <WatchIcon />
              <Typography
                variant="Body_medium_14"
                sx={{ color: theme.palette.grey[99] }}
              >
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
            </Stack>
          </Stack>

          <Stepper
            activeStep={0}
            orientation="vertical"
            connector={<CustomStepConnector />}
            sx={{ width: "100%", mt: 2, position: "relative" }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <CustomStepLabel>{label}</CustomStepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid container>
            <Grid item xs={12}>
              <AnimateButton>
                <LoadingButton
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{
                    marginTop: "15px",
                    minWidth: "100%",
                  }}
                  onClick={handleClick}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: "60px",
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loader}
                >
                  {userData && userData?.userType === "nonGuest"
                    ? "View Dashboard"
                    : "confirm"}
                </LoadingButton>
              </AnimateButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

PendingApproval.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};

export default PendingApproval;
