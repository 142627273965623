import {
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputLabel from "../../components/common/formStyle/InputLabel";

import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  StyledTypography,
  FormInputWrapper,
  StyledAction,
} from "./AuthStyle";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";
const UnlockAccount = () => {
  const navigation = useNavigate();
  const theme = useTheme();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [loading, setLoading] = useState(false);
  const sendOtp = (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError("Email is a required field");
      return;
    }
    setLoading(true);
    GetAxiosInstance(
      `auth/unlockAccountOtp`,
      'post',
      { email },
      {
        'journey-type': 'unlockAccount ',
      }
    ).then((res) => {
      if (res?.data?.codes === 200) {
        setLoading(false);
        localStorage.setItem('email', email);
        navigation('/verify-email');
      } else {
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  };
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle component="form" autoComplete="off">
            <Stack>
              <FormInfoBox>
                <StyledTypography variant="Body_semibold_20">
                  Unlock your account
                </StyledTypography>
                <Typography variant="Body_semibold_24">
                  Please enter your email...
                </Typography>
              </FormInfoBox>

              <FormInputWrapper>
                <Grid item xs={12}>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    variant="filled"
                    type="email"
                    placeholder="Enter email address"
                    id="filled-hidden-label-normal"
                    fullWidth
                    inputProps={{ "aria-label": "Without label" }}
                    hiddenLabel
                    helperText={emailError && emailError}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                  />
                </Grid>
              </FormInputWrapper>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={sendOtp}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: "60px",
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Send OTP
                </LoadingButton>
              </AnimateButton>

              {/* <Typography
                textAlign="center"
                mt={4}
                underline="none"
                variant="Body_medium_16"
              >
                I remember my password. &nbsp;
                <StyledAction
                  // underline="none"
                  variant="Body_medium_16"
                  // component={RouterLink}
                  to="/login"
                >
                  Log In
                </StyledAction>
              </Typography> */}
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
};
export default UnlockAccount;
