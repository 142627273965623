import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import MainCard from "../../components/common/MainCard";
import {
  HelloText,
  MainCardBox,
  SubCardMainInfo,
  SubCardTopInfo,
  SubCardWeapper,
  SubTableCardWeapper,
  TableCellDataDesc,
  TableCellDataDesc1,
  TableTitleTypography,
} from "./DashboardStyle";
import { BoxWrapper, StyledDivider } from "../../components/common/style";
import {
  TableCellData,
  TableCellDataDashboard,
  TableCellHead,
  TableRows,
} from "../../components/common/formStyle/Table";
import DashboardSidebar from "../../components/sidebar/DashboardSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { set } from "lodash";
import { getUserData } from "../../components/common/common";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";

// table data
function createData(time, description, type, amount) {
  return { time, description, type, amount };
}

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
}));
const Dashboard = () => {
  const theme = useTheme();
  let navigator = useNavigate();
  const { state } = useLocation();
  const [Pending, setPending] = useState([]);
  const [open, setOpen] = useState(false);
  const [Booked, setBooked] = useState([]);
  const [userData, setUserData] = useState();
  const [balanceAccess, setBalanceAccess] = useState();
  const [basicStatement, setBasicStatement] = useState();
  const [plushStatement, setPlushStatement] = useState();

  useEffect(() => {
    if (!state) {
      navigator("/home");
    }
    let userData = getUserData();
    setUserData(userData);
    const Pending = state?.transactions.filter(
      (item) => item.Status === "Pending"
    );
    const Booked = state?.transactions;
    
    setBooked(Booked);
    setPending(Pending);
    GetAxiosInstance(
      `orchestrator/get-consent`,
      'get',
      {},
      {
        'journey-type': 'Dashboard',
      }
    ).then((res) => {
      if (res.data?.codes === 200) {
        let consentData =
          res.data?.data.consents.length &&
          res.data?.data.consents.find((item) => {
            return item.dashboardAvailable;
          });
        let consent = res.data?.data.consents;

        if (consent.length) {
          let allProducts = [];
          consent?.forEach((v) => {
            if (
              v?.consent?.data?.products.length &&
              v?.consent?.data?.products
            ) {
              allProducts.push(v?.consent?.data?.products);
            }
          });
          allProducts = allProducts.flat(2);
          allProducts?.filter((item) => {
            if (item.code === 'balance') {
              setBalanceAccess(true);
            }
            if (item.code === 'statement_plus') {
              setPlushStatement(true);
            }
            if (item.code === 'statement_basic') {
              setBasicStatement(true);
            }
          });
        }
      } else {
        toast.error(
          res?.response?.data?.message || res.message || 'Something went wrong!'
        );
      }
    });
  }, []);
  return (
    <RootStyle>
      {/* <DashboardSidebar
        state={state}
        isOpenDashboardSidebar={open}
        onCloseDashboardSidebar={() => setOpen(false)}
      /> */}
      <Container disableGutters>
        <BoxWrapper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                alignItems="end"
                direction="row"
                justifyContent="space-between"
              // spacing={1}
              >
                <HelloText>Hello!</HelloText>

                <Stack direction="column" alignItems="end">
                  <Typography variant="Body_semibold_24">
                    {userData?.firstName}
                  </Typography>
                  <Typography variant="Body_medium_16">
                    Account number: {state?.accountNumber}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            {balanceAccess && (
              <Grid item xs={12}>
                <MainCardBox>
                  <SubCardWeapper>
                    <SubCardTopInfo>
                      <Typography variant="Body_medium_20" color="orange.main">
                        Balance
                      </Typography>
                      <Typography variant="Body_light_14" color="grey.light">
                        Updated a few seconds ago
                      </Typography>
                    </SubCardTopInfo>

                    <StyledDivider />

                    <SubCardMainInfo>
                      <Stack alignItems="center" spacing={1}>
                        <Typography variant="Body_semibold_24">
                          ₦{" "}
                          {(state?.balance || 0).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                        <Typography
                          variant="Body_light_14"
                          color="grey.lighter"
                        >
                          {/* {("state", state)} */}
                          Available Balance
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" spacing={1}>
                        <Typography variant="Body_semibold_24">
                          ₦{" "}
                          {(state?.balance || 0).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                        <Typography
                          variant="Body_light_14"
                          color="grey.lighter"
                        >
                          Current Balance
                        </Typography>
                      </Stack>
                    </SubCardMainInfo>
                  </SubCardWeapper>
                </MainCardBox>
              </Grid>
            )}
            {/* {plushStatement && (
              <Grid item xs={12}>
                <MainCardBox>
                  <SubCardWeapper>
                    <SubCardTopInfo>
                      <Typography variant="Body_medium_20" color="orange.main">
                        Summary
                      </Typography>
                      <Typography variant="Body_light_14" color="grey.light">
                        Updated a few seconds ago
                      </Typography>
                    </SubCardTopInfo>

                    <StyledDivider />

                    <SubCardMainInfo>
                      <Stack alignItems="center" spacing={1}>
                        <Typography variant="Body_semibold_24">
                          ₦ 1,800.00
                        </Typography>
                        <Typography
                          variant="Body_light_14"
                          color="grey.lighter"
                        >
                          Available Balance
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" spacing={1}>
                        <Typography variant="Body_semibold_24">
                          ₦ 1,200.00
                        </Typography>
                        <Typography
                          variant="Body_light_14"
                          color="grey.lighter"
                        >
                          Current Balance
                        </Typography>
                      </Stack>
                    </SubCardMainInfo>
                  </SubCardWeapper>
                </MainCardBox>
              </Grid>
            )} */}
            {Pending.length ? (
              <Grid item xs={12}>
                <MainCardBox>
                  <SubCardWeapper>
                    <SubCardTopInfo my={2}>
                      <TableTitleTypography color="orange.main">
                        Pending Transactions
                      </TableTitleTypography>
                      <Typography variant="Body_light_14" color="grey.light">
                        Updated a few seconds ago
                      </Typography>
                    </SubCardTopInfo>

                    <TableContainer sx={{ py: 2 }}>
                      <Table
                        sx={{
                          minWidth: 350,
                        }}
                      >
                        <TableHead>
                          <TableRows>
                            <TableCellHead>Time</TableCellHead>
                            <TableCellHead>Descriptions</TableCellHead>
                            <TableCellHead>Type</TableCellHead>
                            <TableCellHead>Amount</TableCellHead>
                          </TableRows>
                        </TableHead>
                        <TableBody>
                          {Pending?.map((row, index) => (
                            <TableRow hover key={index}>
                              <TableCellDataDashboard>
                                {moment(row.BookingDateTime).format(
                                  "DD MMM YYYY"
                                )}
                              </TableCellDataDashboard>
                              <TableCellDataDashboard
                                sx={{ maxWidth: { sm: "100%", xs: 0 } }}
                              >
                                {row.TransactionInformation}
                              </TableCellDataDashboard>
                              <TableCellDataDashboard>
                                {row.Status}
                              </TableCellDataDashboard>
                              <TableCellDataDashboard>{`₦ ${row.Amount.Amount}`}</TableCellDataDashboard>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </SubCardWeapper>
                </MainCardBox>
              </Grid>
            ) : (<></>)}


            <Grid item xs={12} >
              <MainCardBox>
                <SubTableCardWeapper>
                  <SubCardTopInfo my={2}>
                    <TableTitleTypography color="orange.main">
                      Transactions
                    </TableTitleTypography>
                    <Typography variant="Body_light_14" color="grey.light">
                      Updated a few seconds ago
                    </Typography>
                  </SubCardTopInfo>

                  <TableContainer sx={{ py: 2 }}>
                    <Table
                      sx={{
                        minWidth: 350,
                      }}
                    >
                      <TableHead>
                        <TableRows>
                          <TableCellHead>Time</TableCellHead>
                          <TableCellHead>Descriptions</TableCellHead>
                          <TableCellHead>Type</TableCellHead>
                          <TableCellHead>Amount</TableCellHead>
                        </TableRows>
                      </TableHead>
                      <TableBody>
                        {Booked?.map((row, index) => (
                          <TableRow hover key={index}>
                            <TableCellDataDashboard>
                              {moment(row.BookingDateTime).format(
                                "DD MMM YYYY"
                              )}
                            </TableCellDataDashboard>
                            <TableCellDataDashboard
                              sx={{ maxWidth: { sm: "100%", xs: 0 } }}
                            >
                              {row.TransactionInformation}
                            </TableCellDataDashboard>
                            <TableCellDataDashboard>
                              {row.Status}
                            </TableCellDataDashboard>
                            <TableCellDataDashboard>{`₦ ${row.Amount.Amount}`}</TableCellDataDashboard>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </SubTableCardWeapper>
              </MainCardBox>
            </Grid>
          </Grid>
        </BoxWrapper>{" "}
      </Container>{" "}
    </RootStyle>
  );
};

export default Dashboard;
