import { Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const RootStyle = styled(Box)(({ theme }) => ({
  height: "80vh",
  margin: 0,

  [theme.breakpoints.down("sm")]: {
    // height: "100vh",
    // display: "contents",
  },
}));

export const FromWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  width: "100%",
  // [theme.breakpoints.down("sm")]: {
  //   height: "70vh",
  // },

  [theme.breakpoints.up("sm")]: {
    margin: 0,
  },
}));

export const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    // minHeight: "90vh",
  },
}));

export const FormStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(4, 3),
  margin: theme.spacing(0, 1),
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    border: `2px solid ${theme.palette.primary.light}`,
    margin: theme.spacing(0),
    width: "550px",
    padding: theme.spacing(6, 3.75),
  },
}));
export const IncomeDetailsStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(4, 3),
  margin: theme.spacing(0, 1),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    border: `2px solid ${theme.palette.primary.light}`,
    margin: theme.spacing(0),
    width: '550px',
    padding: theme.spacing(6, 3.75),
  },
}));

export const FormInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "28px 0",
  marginBottom: theme.spacing(4),
}));

export const FormInputWrapper = styled(FormInfoBox)(({ theme }) => ({
  gap: theme.spacing(4, 0),
}));

export const FormInputWrapperAccountSetup = styled(FormInfoBox)(
  ({ theme }) => ({
    gap: theme.spacing(2, 0),
  })
);

export const SignupBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  top: 15,
  right: 10,
  justifyContent: "flex-end",

  [theme.breakpoints.up("sm")]: {
    top: -40,
    right: 100,
  },
}));

export const NoAccountText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_14,
  color: theme.palette.grey.darker,

  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(5),
}));

export const StyledAction = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.orange.main,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.lighter,
}));

export const SignUpWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

export const EmailTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_14,

  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_light_16,
  },
}));

//

export const SignupBg = styled(FromWrapper)(({ theme }) => ({
  background: "transparent",

  [theme.breakpoints.up("sm")]: {
    background: `linear-gradient(to right, ${theme.palette.primary.main} 50%, ${theme.palette.primary.light} 50%)`,
  },
}));
