import {
  Box,
  Collapse,
  Dialog,
  Divider,
  Drawer,
  // IconButton,
  // Select,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import useResponsive from "../../hooks/useResponsive";
import Scrollbar from "../common/Scrollbar";
import { Link as RouterLink } from "react-router-dom";

// import Bank1 from "../../assets/Bank1.png";
// import Bank3 from "../../assets/Bank3.png";
import Bank from "../../assets/Bank.svg";
import User from "../../assets/User.svg";
import BANKLIST from "../../_mockData_/BankList";

import {
  AccountLinkWrapper,
  AddAccountWrapper,
  BankInfoWrapper,
  BankNameWrapper,
  DashboardSidebarWrapper,
  // LogoWrapper,
} from "./SidebarStyle";
import { FillButton, OutlineButton } from "../common/style";
import AnimateButton from "../common/formStyle/AnimateButton";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import GetAxiosInstance from "../common/api";
import banksList from '../../util/bankList';
// import { SelectAllRounded } from "@mui/icons-material";

// ----------------------------------------------------------------------
const DRAWER_WIDTH = 390;

// ----------------------------------------------------------------------

// const ExpandMore2 = styled((props) => {
//   const { expand2, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(135deg)" : "rotate(0deg)",
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

DashboardSidebar.propTypes = {
  isOpenDashboardSidebar: PropTypes.bool,
  onCloseDashboardSidebar: PropTypes.func,
};
export default function DashboardSidebar({
  state,
  isOpenDashboardSidebar,
  onCloseDashboardSidebar,
}) {
  const [selectedBank, setSelectedBank] = useState();
  const isDesktop = useResponsive('up', 'md');
  const [expanded2, setExpanded2] = useState(true);
useEffect(() => {
  const fetchData = async () => {
    try {
      const bankList = await banksList({
        'journey-type': 'Dashboard',
      });
      if (bankList.length) {
        const res = await GetAxiosInstance(
          `orchestrator/get-consent`,
          'get',
          {},
          {
            'journey-type': 'Dashboard',
          }
        );
        if (res.data?.codes === 200) {
          const selectedConsent =
            res.data?.data?.consents?.length &&
            res.data?.data?.consents.find(
              (i) => i.consentId === state?.consentId
            );
          let selectedBankInfo = bankList.find((item) => {
            return item.bankName === selectedConsent?.bankName;
          });
          if (!selectedBankInfo) {
            selectedBankInfo = bankList.find((item) => {
              return item.id === 1;
            });
          }
          setSelectedBank(selectedBankInfo);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);
  const handleExpandClick1 = () => {
    setExpanded2(!expanded2);
  };
  const theme = useTheme();

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DashboardSidebarWrapper>
          <Divider
            sx={{
              borderColor: '#002E45',
              borderWidth: 2,
              display: { md: 'block', xs: 'none' },
            }}
          />

          <AccountLinkWrapper>
            <Typography variant="Body_medium_16" color="grey.darker">
              You have &nbsp;
              <span style={{ color: theme.palette.orange.main }}>1</span>
              &nbsp; account linked to
              <br />
              E-DocOnline.
            </Typography>
          </AccountLinkWrapper>

          <Divider sx={{ borderColor: '#002E45', borderWidth: 2 }} />

          <AddAccountWrapper>
            <Typography variant="Body_semibold_20" mt={5}>
              Add Bank Account
            </Typography>

            <AnimateButton>
              <FillButton
                size="large"
                component={RouterLink}
                to="/home"
                type="submit"
                variant="contained"
              >
                Connect Your Bank{' '}
              </FillButton>
            </AnimateButton>

            <Box>
              <Box sx={{ width: '100%' }}>
                <BankNameWrapper>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box
                      component="img"
                      sx={{
                        height: '60px',
                        borderRadius: '50%',
                      }}
                      src={selectedBank?.icon}
                    />
                    <Typography variant="Body_medium_18">
                      {selectedBank?.displayName}
                    </Typography>
                  </Stack>
                  {/* <ExpandMore2
                    expand={expanded2}
                    onClick={handleExpandClick1}
                    aria-expanded={expanded2}
                    aria-label="show more"
                  > */}
                  <CloseIcon
                    sx={{ fill: theme.palette.orange.main, cursor: 'pointer' }}
                  />
                  {/* </ExpandMore2> */}
                </BankNameWrapper>

                <Collapse in={expanded2}>
                  <BankInfoWrapper>
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <Box component="img" src={Bank} alt="" />
                      <Typography variant="Body_medium_16" color="grey.darker">
                        {state?.accountNumber}
                      </Typography>
                    </Stack>

                    <Divider sx={{ borderColor: '#002E45', borderWidth: 2 }} />

                    <Stack direction="row" alignItems="center" spacing={3}>
                      <Box component="img" src={User} alt="" />
                      <Typography variant="Body_medium_16" color="grey.darker">
                        {state?.accountName}
                      </Typography>
                    </Stack>

                    <Divider sx={{ borderColor: '#002E45', borderWidth: 2 }} />
                  </BankInfoWrapper>
                </Collapse>
              </Box>
            </Box>

            <AnimateButton>
              <OutlineButton
                size="large"
                component={RouterLink}
                to="/view-plans"
                type="submit"
                variant="contained"
                sx={{
                  color: 'orange.main',
                }}
              >
                Remove all accounts
              </OutlineButton>
            </AnimateButton>
          </AddAccountWrapper>
        </DashboardSidebarWrapper>
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Dialog
          open={isOpenDashboardSidebar}
          onClose={onCloseDashboardSidebar}
          anchor="top"
          PaperProps={{
            sx: {
              width: '100%',
              bgcolor: theme.palette.primary.light,
            },
          }}
        >
          {renderContent}
        </Dialog>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: theme.palette.primary.light,
              border: 'none',
              zIndex: 10,
              borderRadius: 0,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
