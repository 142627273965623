import React, { useCallback, useEffect, useState } from "react";

import {
  Stack,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";

import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  EmailTypography,
} from "./AuthStyle";
import { useTheme } from "@mui/material/styles";

import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

export default function VerifyAccount() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [phoneLastDigits, setPhoneLastDigits] = useState();
  const theme = useTheme();
  const [otp, setOtp] = useState();
  //   const [timer, setTimer] = useState(30);
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    if (otp && phoneLastDigits) {
      GetAxiosInstance(
        `auth/verifyUnlockOtp`,
        'post',
        {
          email: localStorage.getItem('email'),
          otp,
          phoneLastDigits,
        },
        {
          'journey-type': 'verifyUnlockOtp',
        }
      ).then((res) => {
        if (res?.data?.codes === 200) {
          localStorage.setItem('unlockToken', res?.data?.data?.token);
          setLoading(false);
          navigate('/reset-password');
        } else {
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    } else {
      toast.error("Please Enter and phone last digits.");
    }
  };

  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle component="form" autoComplete="off">
            <Stack>
              <FormInfoBox>
                <Typography variant="Body_semibold_24">
                  Content verification
                </Typography>
                <EmailTypography mx={1}>
                  We have sent a 6 digit OTP code to your email address (
                  {state?.email}). Kindly input to verify and continue
                </EmailTypography>
              </FormInfoBox>

              <Grid item xs={12}>
                <OtpInput
                  value={otp}
                  onChange={(otpNumber) => setOtp(otpNumber)}
                  numInputs={6}
                  containerStyle={{ justifyContent: 'space-between' }}
                  inputStyle={{
                    width: '100%',
                    height: '50px',
                    color: 'white',
                    margin: 8,
                    backgroundColor: theme.palette.primary.light,
                    padding: '10px',
                    border: 'none',
                    borderRadius: 12,
                    ':hover': {
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                  focusStyle={{
                    outline: 'none',
                    border: 'none',
                  }}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </Grid>
              <FormInfoBox mt={3} sx={{ mb: 1 }}>
                <EmailTypography mx={1}>
                  Please enter the last 6 digits of your phone number.
                </EmailTypography>
              </FormInfoBox>
              <Grid item xs={12}>
                <OtpInput
                  value={phoneLastDigits}
                  onChange={(phoneLastDigits) =>
                    setPhoneLastDigits(phoneLastDigits)
                  }
                  numInputs={6}
                  containerStyle={{ justifyContent: 'space-between' }}
                  inputStyle={{
                    width: '100%',
                    height: '50px',
                    color: 'white',
                    margin: 8,
                    backgroundColor: theme.palette.primary.light,
                    padding: '10px',
                    border: 'none',
                    borderRadius: 12,
                    ':hover': {
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                  focusStyle={{
                    outline: 'none',
                    border: 'none',
                  }}
                />
              </Grid>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="contained"
                  style={{
                    width: '100%',
                    margin: '30px 0',
                  }}
                  onClick={handleClick}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Verify
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
