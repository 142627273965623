import React, { useEffect, useState } from 'react';

import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
  useTheme,
  Box,
} from '@mui/material';

import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  FormInputWrapper,
  GuestBg,
} from './GuestStyle';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import InputLabel from '../../components/common/formStyle/InputLabel';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import GetAxiosInstance from '../../components/common/api';
import { LoadingButton } from '@mui/lab';
import { FillButton, OutlineButton } from '../../components/common/style';
import PolicyAndTermsText from '../../components/common/PolicyAndTermsText';
import { toast } from 'react-toastify';
import decodeJwt from 'jwt-decode';

// ----------------------------------------------------------------------

export function GuestLogin() {
  const { id } = useParams();
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  useEffect(() => {
    localStorage.setItem('deepLinkId', id);
  }, []);
  const handleClick = () => {
    if (!email) {
      toast.error('Please Enter Email');
      return;
    }
    if (!validateEmail(email)) {
      toast.error('Please Enter Valid Email');
      return;
    }
    setLoading(true);
    const requestObj = {
      email,
    };
    GetAxiosInstance(
      `orchestrator/mandate-template/${id}`,
      'post',
      requestObj,
      {
        'journey-type': 'Mandate',
      }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          const postObj = {
            journey: 'CUSTOMER_REGISTRATION',
            eventName: 'CUSTOMER_REGISTRATION.INITIATED',
            status: 'SUCCESS',
            uniqueIdentifier: email,
            subIdentifier: '',
            data: {
              req: requestObj,
              res: res.data.userId,
            },
            source: 'BUSINESS_PARTNER',
          };
          // GetAxiosInstance(`log/eventLog`, 'post', postObj);
          setLoading(false);
          let decode = decodeJwt(res?.data?.data?.user?.accessToken);
          let obj = {
            accessToken: res?.data?.data?.user?.accessToken,
            refreshToken: res?.data?.data?.user?.refreshToken,
          };
          obj = { ...obj, ...decode };
          localStorage.setItem('userData', JSON.stringify(obj));
          if (pathname.includes('mandate-request')) {
            navigate(`/direct-debit/${id}`, { state: { ...requestObj } });
          } else {
            navigate('/home');
          }
        } else {
          setLoading(false);
          toast.error(res?.response?.data?.message);
          // const postObj = {
          //   journey: 'CUSTOMER_REGISTRATION',
          //   eventName: 'CUSTOMER_REGISTRATION.INITIATED',
          //   status: 'ERROR',
          //   uniqueIdentifier: email,
          //   subIdentifier: '',
          //   data: {
          //     req: requestObj,
          //     res: res.data.message,
          //   },
          //   source: 'BUSINESS_PARTNER',
          // };
          // GetAxiosInstance(`log/eventLog`, 'post', postObj);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || err.message);
      });
  };
  useEffect(() => {
    localStorage.removeItem('userData');
  }, []);
  return (
    <>
      <GuestBg>
        <ContentStyle sx={{ minHeight: '100vh' }}>
          <FormStyle component="form" autoComplete="off">
            <Stack>
              <FormInfoBox>
                <Typography variant="Body_semibold_24">
                  Please enter details
                </Typography>
              </FormInfoBox>
              <FormInputWrapper>
                <Grid item xs={12}>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    variant="filled"
                    type="email"
                    placeholder="Enter email address"
                    id="filled-hidden-label-normal"
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </FormInputWrapper>

              <Grid container spacing={2} alignItems="center" mt={1} mb={5}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={4}
                  >
                    <Grid item>
                      <AnimateButton>
                        <OutlineButton
                          size="large"
                          type="submit"
                          variant="contained"
                          component={RouterLink}
                          to="/login"
                        >
                          Go Back
                        </OutlineButton>
                      </AnimateButton>
                    </Grid>
                    <Grid item>
                      <AnimateButton>
                        {/* <FillButton
                            size="large"
                            type="button"
                            variant="contained"
                            onClick={handleClick}
                            // component={RouterLink}
                            // to="/home"
                          >
                            Continue
                          </FillButton> */}
                      </AnimateButton>
                      <AnimateButton>
                        <LoadingButton
                          size="large"
                          type="submit"
                          variant="contained"
                          sx={{ width: { xs: 'auto', sm: '215px' } }}
                          onClick={handleClick}
                          loadingIndicator={
                            <CircularProgress
                              style={{
                                padding: '60px',
                                color: theme.palette.orange.main,
                              }}
                            />
                          }
                          loading={loading}
                        >
                          guest Login
                        </LoadingButton>
                      </AnimateButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <PolicyAndTermsText />
            </Stack>
          </FormStyle>
        </ContentStyle>
      </GuestBg>
    </>
  );
}
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
