import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  ButtonGroup,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import GetAxiosInstance from '../../components/common/api';
import { getUserData } from '../../components/common/common';
import Loader from '../../components/common/Loader';
import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav';
import {
  MainCardStyled,
  PageWrapper,
  StyledTableCellData,
  StyledTableCellDataItem,
  TableCellHead,
} from './CategoryInsightsStyle';

function createData(Item, Percentage, Amount) {
  return { Item, Percentage, Amount };
}

const monthRange = [
  { title: '1 Month', value: 1 },
  { title: '3 Months', value: 3 },
  { title: '6 Months', value: 6 },
  { title: '9 Months', value: 9 },
  { title: '12 Months', value: 12 },
];

const ExpandList = ({ updateQuantity }) => {
  let { state } = useLocation();
  const [transactionGroup, setTransactionGroup] = useState([]);
  const [loader, setLoader] = useState(false);
  let [nameSearch, setNameSearch] = useState('');
  let [filterValue, setFilterValue] = useState([]);
  let [dashboardData, setDashboardData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [consent, setConsent] = useState();
  const [endDate, setEndDate] = useState([]);
  const [selectedType, setSelectedType] = useState(
    state?.selectedType || 'tran_amount_total_for_period'
  );

  useEffect(() => {
    let responseValue = [];
    if (nameSearch) {
      responseValue = transactionGroup.filter((item) => {
        return (
          (item.category_lvl1 &&
            item?.category_lvl1
              .toLowerCase()
              .includes(nameSearch.toLocaleLowerCase())) ||
          (item.category_lvl2 &&
            item?.category_lvl2
              .toLowerCase()
              .includes(nameSearch.toLocaleLowerCase()))
        );
      });
    } else {
      responseValue = transactionGroup;
    }
    setOffSet(0 * 10);
    setFilterValue(responseValue);
  }, [nameSearch, transactionGroup]);

  useEffect(() => {
    setOffSet(currentPage * 10);
  }, [currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      let endDate = moment().format('YYYY-MM-DD');
      setLoader(true);

      try {
        const consentRes = await GetAxiosInstance(
          `orchestrator/get-consent`,
          'get',
          {},
          {
            'journey-type': 'Dashboard',
          }
        );
        if (consentRes.data?.codes === 200) {
          let accountNumberList = consentRes?.data?.data.consents.map((v) => {
            return { title: v.accountNumber, value: v.accountNumber };
          });

          let selectedProduct = consentRes?.data?.data?.consents.find(
            (item) => item.accountNumber === state?.selectAccount
          );

          endDate = selectedProduct?.consent?.data?.dateTo
            ? moment(selectedProduct?.consent?.data?.dateTo).format(
                'YYYY-MM-DD'
              )
            : '';

          setEndDate(endDate);
          setConsent(consentRes.data?.data?.consents);
        } else {
          toast.error(
            consentRes?.response?.data?.message ||
              consentRes.message ||
              'Something went wrong!'
          );
        }

        let dataArray = [];
        let userData = getUserData();
        let data = state?.selectedDateRang.length;
        monthRange.forEach((v) => {
          if (data >= v.value) {
            dataArray.push(v);
          }
        });

        let dashboardPayload = {
          userId: userData?.id,
          date_to: state?.selectedEndDate ? state?.selectedEndDate : endDate,
          n_months: state?.selectMonth,
          account_id: state?.selectAccount,
          accountType: state?.accountType,
        };

        const dashboardRes = await GetAxiosInstance(
          'orchestrator/get-dashboard',
          'post',
          dashboardPayload,
          {
            'journey-type': 'Dashboard',
          }
        );

        if (dashboardRes?.data?.codes === 200) {
          setDashboardData(dashboardRes?.data?.data?.monthly);
        } else {
          setDashboardData([]);
          toast.error(
            dashboardRes?.response?.data?.message || dashboardRes.message
          );
        }

        let payload = {
          user_id: userData?.id,
          date_to: state?.selectedEndDate ? state?.selectedEndDate : endDate,
          n_months: state?.selectMonth,
          account_id: state?.selectAccount,
          tran_threshold: 0,
        };

        const categoryInsightsRes = await GetAxiosInstance(
          'orchestrator/get-category-insights',
          'post',
          payload,
          {
            'journey-type': 'Dashboard',
          }
        );

        if (
          categoryInsightsRes?.data?.codes === 200 &&
          categoryInsightsRes?.data?.data?.transaction_groups.length &&
          categoryInsightsRes?.data?.data?.disposable_income &&
          categoryInsightsRes?.data?.data?.monthly_transactions.length
        ) {
          let result = categoryInsightsRes.data.data;
          let transactions = result?.transaction_groups.filter((item) => {
            return (
              item.category_lvl1 !== null &&
              item?.tran_amount_monthly_average !== null &&
              item.category_lv2 !== null &&
              item.aggregation_lvl === 2
            );
          });
          setTransactionGroup(transactions);
        } else {
          setTransactionGroup([]);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
      } finally {
        setLoader(false);
      }
    };

    fetchData();
  }, []);

  const theme = useTheme();
  return (
    <>
      <DashboardUINav dashboardData={dashboardData} />

      <PageWrapper mt={5}>
        <MainCardStyled>
          <Stack
            my={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="start" gap={1}>
              <Box component={RouterLink} to="/category-insights">
                <ArrowBackIcon
                  sx={{
                    fill: (theme) => theme.palette.common.white,
                  }}
                />
              </Box>
              <Typography variant="Body_medium_18">
                Transaction Groups
              </Typography>
            </Stack>
            <TextField
              variant="filled"
              id="filled-hidden-label-normal"
              placeholder="Search for a bank..."
              autoComplete="off"
              hiddenLabel
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setNameSearch(e.currentTarget.value);
              }}
            />
          </Stack>

          <TableContainer>
            <Table
              stickyHeader
              sx={{
                minWidth: 350,
              }}
            >
              {loader ? (
                <Loader />
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCellHead>Type</TableCellHead>
                      <TableCellHead>Category</TableCellHead>
                      <TableCellHead>Subcategory</TableCellHead>
                      <TableCellHead>Amount</TableCellHead>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filterValue
                      .slice(offSet, offSet + 10)
                      .map((row, index) => (
                        <TableRow hover key={index}>
                          <StyledTableCellDataItem>
                            {row.tran_type}
                          </StyledTableCellDataItem>
                          <StyledTableCellData>
                            {row?.category_lvl1}
                          </StyledTableCellData>
                          <StyledTableCellData>
                            {row?.category_lvl2}
                          </StyledTableCellData>
                          <StyledTableCellData>
                            ₦{' '}
                            {(row?.[selectedType] || 0).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{' '}
                          </StyledTableCellData>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 3.5,
            }}
          >
            <Box width={'130px'} />
            <Box width={'110px'}>
              <Button
                fullWidth
                size="large"
                sx={{
                  background: theme.palette.primary.light,
                  px: 5,
                }}
              >
                {' '}
                Next{' '}
              </Button>
            </Box>
            <Stack direction="row" alignItems="center" gap={1}>
              <Increment
                quantity={1}
                updateQuantity={updateQuantity}
                setCurrentPage={setCurrentPage}
                pageCount={parseInt(filterValue.length / 10)}
              />{' '}
              of {parseInt(filterValue.length / 10)}
            </Stack>
          </Box>
        </MainCardStyled>
      </PageWrapper>
    </>
  );
};

const Increment = ({
  itemId,
  quantity,
  updateQuantity,
  setCurrentPage,
  pageCount,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(quantity);

  const incrementHandler = () => {
    setValue(value - 1);
  };

  const decrementHandler = () => {
    if (pageCount > value) {
      setValue(value + 1);
    }
  };

  useEffect(() => {
    setCurrentPage(value);
  }, [value]);

  return (
    <ButtonGroup
      size="large"
      variant="text"
      color="inherit"
      sx={{
        border: 'none',
        backgroundColor: 'transparent',
      }}
    >
      <Button
        key="three"
        disabled={value <= 1}
        onClick={incrementHandler}
        sx={{
          pr: 1,
          pl: 1,
          minWidth: '0px !important',
          backgroundColor: theme.palette.primary.light,
          borderRight: 'none !important',

          '&:hover': {
            backgroundColor: 'transparent ',
          },
        }}
      >
        <ChevronLeftIcon
          fontSize="medium"
          sx={{ fill: theme.palette.grey.lighter }}
        />
      </Button>
      <Button
        key="two"
        sx={{
          pl: 3,
          pr: 3,
          backgroundColor: theme.palette.primary.light,
          borderRight: 'none !important',

          '&:hover': {
            backgroundColor: 'transparent ',
          },
        }}
      >
        {value}
      </Button>
      <Button
        key="one"
        onClick={decrementHandler}
        sx={{
          pl: 1,
          pr: 1,
          minWidth: '0px !important',
          backgroundColor: theme.palette.primary.light,
          borderRight: 'none !important',

          '&:hover': {
            backgroundColor: 'transparent ',
          },
        }}
      >
        <ChevronRightIcon
          fontSize="medium"
          sx={{ fill: theme.palette.grey.lighter }}
        />
      </Button>
    </ButtonGroup>
  );
};

Increment.propTypes = {
  itemId: PropTypes.number,
  quantity: PropTypes.number,
  updateQuantity: PropTypes.func,
};

export default ExpandList;
