import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  StyledTypography,
  FormInputWrapperAccountSetup,
} from "./AuthStyle";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useLocation } from "react-router-dom";
import InputLabel from "../../components/common/formStyle/InputLabel";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { useEffect, useState } from "react";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const PhoneInputStyle = styled(PhoneInput)(({ theme }) => ({
  ".selected-flag": {
    background: "transparent !important",
    "&:hover": {
      background: "transparent !important",
    },
    "&:focus": {
      background: "transparent !important",
    },
  },

  ".country-list": {
    background: `${theme.palette.primary.light} !important`,
  },

  ".country": {
    "&:hover": {
      background: `${theme.palette.primary.main} !important`,
    },
  },

  ".country.highlight": {
    background: `${theme.palette.primary.lighter} !important`,
    "&:hover": {
      background: `${theme.palette.primary.light} !important`,
    },
  },
}));

// ----------------------------------------------------------------------

export function AccountSetup() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { state } = useLocation();
  const [mobile, setMobile] = useState();
  const [company, setCompany] = useState();
  const [mobileError, setMobileError] = useState("");
  useEffect(() => {
    if (!state) {
      navigate("/signup");
    }
  }, []);
  const onSubmit = () => {
    let accountDetails = {
      mobile: mobile,
      company: company,
    };
    if (!mobile || mobile.length > 13 || mobile.length < 12) {
      setMobileError("Please enter valid mobileNumber");
    } else {
      let userData = { ...state, ...accountDetails };
      if (mobile) {
        setLoading(true);
        GetAxiosInstance(`auth/register`, 'post', userData, {
          'journey-type': 'Register',
        }).then((res) => {
          setLoading(false);
          if (res?.data?.codes === 200) {
            navigate('/account-created');
          } else {
            toast.error(res?.response?.data?.message || res.message);
          }
        });
      }
    }
  };
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle>
            <Stack>
              <FormInfoBox>
                <StyledTypography variant="Body_semibold_20">
                  Almost Done
                </StyledTypography>
                <Typography variant="Body_semibold_24">
                  Account Setup
                </Typography>
              </FormInfoBox>

              <FormInputWrapperAccountSetup>
                <Grid item xs={12}>
                  <InputLabel>Mobile Phone Number</InputLabel>
                  <PhoneInputStyle
                    country={"ng"}
                    onChange={(e) => {
                      setMobile(e);
                      setMobileError("");
                    }}
                    onlyCountries={["ng", "gb"]}
                    inputStyle={{
                      paddingLeft: 70,
                      width: "100%",
                      height: "56px",
                      borderRadius: 8,
                      backgroundColor: theme.palette.primary.light,
                      border: "none",
                      color: theme.palette.common.white,
                    }}
                    buttonStyle={{
                      width: "60px",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: theme.palette.primary.main,
                      border: "none",
                      "&:hover": {
                        backgroundColor: "red !important",
                      },
                    }}
                    value={mobile}
                  />
                  <p className="error-mess">{mobileError}</p>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Company (Optional)</InputLabel>
                  <TextField
                    variant="filled"
                    type="text"
                    placeholder="Enter company’s name"
                    id="filled-hidden-label-normal"
                    fullWidth
                    inputProps={{ "aria-label": "Without label" }}
                    hiddenLabel
                    value={company}
                    onChange={(e) => {
                      return setCompany(e.target.value);
                    }}
                  />
                </Grid>
              </FormInputWrapperAccountSetup>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={onSubmit}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: "60px",
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Create Account
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
