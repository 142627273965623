import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import {
  ContentStyle,
  FormInfoBox,
  FormInputWrapper,
  FormStyle,
  SignupBg,
} from '../Auth/AuthStyle';

import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import CustomizedCheckbox from '../../components/common/formStyle/CheckBoxStyled';
import InputLabel from '../../components/common/formStyle/InputLabel';
import GetAxiosInstance from '../../components/common/api';
import { toast } from 'react-toastify';

export function EmployeeVerifyEmail() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  // Form validation schema using Yup
  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required'),
    isCheck: yup
      .boolean()
      .oneOf([true], 'You must accept the Privacy Policy and Terms of Service'),
  });

  // useForm hook for managing form state
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  // Form submission handler
  const onSubmit = (data) => {
    setLoading(true);
    GetAxiosInstance(
      `orchestrator/incomeVerification/verifyUser/${id}`,
      'post',
      {
        email: data.email,
      },
      {
        'journey-type': 'income-verification',
      }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          const obj = {
            accessToken: res?.data?.data?.user?.accessToken,
          };
          localStorage.setItem('userData', JSON.stringify(obj));
          setLoading(false);
          navigate(`/income-verification/partner/details/${id}`, {
            state: res?.data?.data,
          });
        } else {
          setLoading(false);
          toast.error(res?.response?.data?.message || 'Something went wrong');
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || 'Something went wrong');
      });
  };

  return (
    <>
      <SignupBg>
        <ContentStyle sx={{ minHeight: '100vh' }}>
          {/* The form starts here */}
          <FormStyle
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack>
              <FormInfoBox>
                <Typography variant="Body_semibold_24">
                  E-mail Verification
                </Typography>
              </FormInfoBox>

              <FormInputWrapper>
                <Grid item xs={12}>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    variant="filled"
                    type="email"
                    placeholder="Enter email address"
                    fullWidth
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>
              </FormInputWrapper>

              <Grid item xs={12} lg={6} mb={4}>
                <Box
                  sx={{
                    display: 'flex',
                    textAlign: 'start',
                    justifyContent: 'flex-start',
                    gap: 2,
                  }}
                >
                  {/* Controlled Checkbox */}
                  <Controller
                    name="isCheck"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <CustomizedCheckbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />

                  <Typography variant="Body_light_16">
                    By creating an account, you agree to accept our
                    <Box style={{ display: 'flex', gap: 4 }}>
                      <Box
                        sx={{ cursor: 'pointer', color: 'orange.main' }}
                        onClick={() =>
                          window.open(
                            'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+ng+Privacy+Policy.pdf'
                          )
                        }
                      >
                        Privacy Policy
                      </Box>{' '}
                      and
                      <Box
                        sx={{ cursor: 'pointer', color: 'orange.main' }}
                        onClick={() =>
                          window.open(
                            'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+Ng+Terms+%26+Conditions.pdf'
                          )
                        }
                      >
                        Terms of Service
                      </Box>
                    </Box>
                  </Typography>
                </Box>
                {errors.isCheck && (
                  <Typography color="error">
                    {errors.isCheck.message}
                  </Typography>
                )}
              </Grid>

              {/* Submit button */}
              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ width: '100%' }}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Proceed
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </SignupBg>
    </>
  );
}
