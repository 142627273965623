/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";

import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  FormInputWrapper,
} from "./AuthStyle";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import InputLabel from "../../components/common/formStyle/InputLabel";
import PasswordStrengthMeter from "./PassWordStrenth";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { toast } from "react-toastify";
import GetAxiosInstance from "../../components/common/api";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------

export default function PasswordSetup() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [Strength, setStrength] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conPassWord, setConPassWord] = useState("");
  const [conPassWordError, setConPassWordError] = useState("");
  const [loading, setLoading] = useState();
  const theme = useTheme();

  const dataHandler = async (childData) => {};
  const onSubmitFuc = (e) => {
    if (!password) {
      setPasswordError("Please Enter Password");
      return;
    }
    if (!conPassWord) {
      setConPassWordError("Please Enter Confirm Password");
      return;
    } else if (password !== conPassWord) {
      setConPassWordError("Password must match");
      return;
    }
    if (password.length < 8) {
      toast.error("Password must me 8 characters");
      return;
    }
    // if (state?.flow === "resetPassword") {
    let payload = {
      password,
      token: localStorage.getItem("unlockToken"),
    };
    setLoading(true);
    GetAxiosInstance(
      `auth/unlockAccount`,
      'post',
      { ...payload },
      {
        'journey-type': 'unlockAccount',
      }
    ).then((res) => {
      setLoading(false);
      if (res?.data?.codes === 200) {
        toast.success(res?.data?.message);
        localStorage.removeItem('unlockToken');
        localStorage.removeItem('email');

        navigate('/login');
      } else {
        toast.error(res?.response?.data?.message || res.message);
      }
    });
    // } else {
    //   // navigate("/account-setup", { state: state });
    // }
  };

  const handleChange = (e) => {
    let capsCount, smallCount, numberCount, symbolCount;
    capsCount = (password.match(/[A-Z]/g) || []).length;
    smallCount = (password.match(/[a-z]/g) || []).length;
    numberCount = (password.match(/[0-9]/g) || []).length;
    symbolCount = (password.match(/\W/g) || []).length;
    if (capsCount < 1) {
      setError("Must contain one UPPERCASE letter");
      return;
    } else if (smallCount < 1) {
      setError("Must contain one lowercase letter");
      return;
    } else if (numberCount < 1) {
      setError("Must contain one number");
      return;
    } else if (symbolCount < 1) {
      setError("Must contain one special character: @$! % * ? &");
      return;
    }
  };
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle
            component="form"
            autoComplete="off"
            // onSubmit={handleSubmit(onSubmit)}
          >
            <Stack>
              <FormInfoBox>
                <Typography variant="Body_semibold_24">
                  Reset Password
                </Typography>
              </FormInfoBox>

              <FormInputWrapper>
                <Grid item xs={12}>
                  <InputLabel>Create Password</InputLabel>
                  <TextField
                    variant="filled"
                    type="password"
                    placeholder="Password 8+ characters"
                    id="filled-hidden-label-normal"
                    fullWidth
                    onChange={(e) => {
                      handleChange();
                      setPassword(e.target.value);
                      setPasswordError('');
                    }}
                    inputProps={{ 'aria-label': 'Without label  ' }}
                    hiddenLabel
                    helperText={passwordError}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Confirm Password</InputLabel>
                  <TextField
                    variant="filled"
                    type="password"
                    placeholder="Re-enter password"
                    id="filled-hidden-label-normal"
                    fullWidth
                    onChange={(e) => {
                      handleChange();
                      setConPassWord(e.target.value);
                      setConPassWordError('');
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    helperText={conPassWordError}
                  />
                </Grid>
              </FormInputWrapper>

              <Grid item xs={12} mb={4}>
                <PasswordStrengthMeter
                  password={password}
                  actions={dataHandler}
                />
              </Grid>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="contained"
                  style={{ width: '100%' }}
                  onClick={onSubmitFuc}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Proceed
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
