import {
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Table,
  TableRow,
  Typography,
  useTheme,
  TableBody,
  IconButton,
  styled,
  Collapse,
  TableHead,
  TextField,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GetAxiosInstance from '../../components/common/api.js';
import { getUserData } from '../../components/common/common.js';
import CustomizedCheckbox from '../../components/common/formStyle/CheckBoxStyled.js';
import StyledCircularProgress from '../../components/common/StyledCircularProgress.js';
import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav.js';
import { LabelDescription } from '../permission/PermissionStyle.js';
import {
  MainCardStyled,
  PageWrapper,
  StyledTableCellDataItem,
  StyledTableCellData,
  TableCellHead,
  TableWrapper,
  ListForBusiness,
} from './IncomeAndJobVerificationStyle.js';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomPaper } from '../../components/common/style/index.js';
import banksList from '../../util/bankList.js';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const IncomeAndJobVerification = () => {
  const navigator = useNavigate();
  let userData = getUserData();
  const [dashboardData, setDashboardData] = useState([]);
  const [transection, setTransection] = useState([]);
  const [consent, setConsent] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectAccount, setSelectAccount] = useState();
  const [consents, setConsents] = useState();
  const [loader, setLoader] = useState();
  const [accountsNumberList, setAccountsNumberList] = useState();
  const [companyDetailData, setCompanyDetailData] = useState();
  const [metrics, setMetrics] = useState();
  const [selectMonth, setSelectMonth] = useState();
  const [selectedBankLogo, setSelectedBankLogo] = useState();
  const [bankList, setBankList] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [selectedConsent, setSelectedConsent] = useState({});
  const [dateToValueData, setDateToValueData] = useState('');

  const [endDate, setEndDate] = useState();
  const [topTransaction, setTopTransection] = useState({
    inFlow: [],
    outFlow: [],
  });
  const [bottomTransection, setBottomTransection] = useState({
    inFlow: [],
    outFlow: [],
  });
  const [topPaymentSME, setTopPaymentSME] = useState({
    inFlow: [],
    outFlow: [],
  });
  const [topPaymentLME, setTopPaymentLME] = useState({
    inFlow: [],
    outFlow: [],
  });
  const monthRange = [
    { title: '1 Month', value: 1 },
    {
      title: '3 Months',
      value: 3,
    },
    {
      title: '6 Months',
      value: 6,
    },
    {
      title: '9 Months',
      value: 9,
    },
    {
      title: '12 Months',
      value: 12,
    },
  ];
  const [filletElement, setFilletElement] = useState([]);
  const [topInBound, setTopInBound] = useState(false);
  const [topOutBound, setTopOutBound] = useState(false);
  const [bottomInBound, setBottomInBound] = useState(false);
  const [bottomOutBound, setBottomOutBound] = useState(false);
  const [top5InBoundPaymentSME, setTop5InBoundPaymentSME] = useState(false);
  const [top5OutBoundPaymentSME, setTop5OutBoundPaymentSME] = useState(false);
  const [top5InBoundPaymentLME, setTop5InBoundPaymentLME] = useState(false);
  const [top5OutBoundPaymentLME, setTop5OutBoundPaymentLME] = useState(false);
  const [financialMatrics, setFinancialMatrics] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState(null);

  const [statementAccessData, setStatementAccessData] = useState();
  const [isCheckVerification, setIsCheckVerification] = useState({
    bankVerification: true,
    customerVerification: true,
    addressVerification: true,
  });

  const theme = useTheme();
  useEffect(() => {
    let dataArray = [];
    let userData = getUserData();

    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    let endDate = moment().format('YYYY-MM-DD');
    let selectAccountNumber = selectAccount
      ? selectAccount
      : (accountsNumberList && accountsNumberList[0]?.value) || null;
    let selectedConsent = consents?.find((v) => {
      return v?.accountNumber === selectAccountNumber;
    });
    setSelectedDate(selectedConsent);
    let dashboardPayload = {
      userId: userData?.id,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1]?.value,
      account_id: selectAccountNumber,
      consentId: selectedConsent?.consentId,
    };
    if (dateToValue) {
      GetAxiosInstance('orchestrator/get-kyc', 'post', dashboardPayload).then(
        (res) => {
          if (res?.data?.codes === 200) {
            setDashboardData(res?.data?.data?.monthly);
            setCompanyDetailData(res?.data?.data?.companyDetails);
            if (res?.data.data.kycDetails.data.length) {
              setMetrics(res?.data?.data?.kycDetails?.monthly);
              setTransection(res?.data.data.kycDetails.data);
              setTopTransection({
                inFlow: res?.data?.data.kycDetails.top_5_inflows,
                outFlow: res?.data?.data.kycDetails.top_5_outflows,
              });
              setBottomTransection({
                inFlow: res?.data?.data.kycDetails.bottom_5_inflows,
                outFlow: res?.data?.data.kycDetails.bottom_5_outflows,
              });
              setTopPaymentSME({
                inFlow: res?.data?.data.kycDetails.small_business_inflows,
                outFlow: res?.data?.data.kycDetails.small_business_outflows,
              });
              setTopPaymentLME({
                inFlow: res?.data?.data.kycDetails.large_business_inflows,
                outFlow: res?.data?.data.kycDetails.large_business_outflows,
              });
            }
          } else {
            setTransection([]);
            setDashboardData([]);
            setLoader(false);
            toast.error(res?.response?.data?.message || res.message);
          }
        }
      );
    }
  }, [selectAccount, accountsNumberList, consents]);
  useEffect(async () => {
    setBankList(
      await banksList({
        'journey-type': 'Dashboard',
      })
    );
  }, []);
  useEffect(() => {
    setLoader(true);
    let endDate = moment().format('YYYY-MM-DD ');

    let selectedProduct = consent?.find(
      (item) => item.accountNumber === selectAccount
    );
    if (selectedProduct && selectAccount) {
      endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
        'YYYY-MM-DD'
      );
      let dateRangArray = [
        {
          startDate: selectedProduct?.consent?.data?.dateFrom,
          endDate: selectedProduct?.consent?.data?.dateTo,
        },
      ];
      calculateDateRang(dateRangArray, setSelectedDateRang);
      setSelectedConsent(selectedProduct);
    } else {
      let dateRangArray = consent?.map((v) => {
        return {
          startDate: v.consent?.data?.dateFrom,
          endDate: v.consent?.data?.dateTo,
        };
      });
      let allEndDate = consent?.map((v) => v.consent?.data?.dateTo);
      let maxEndDate =
        allEndDate?.length &&
        allEndDate.reduce(function (a, b) {
          return a > b ? a : b;
        });
      calculateDateRang(dateRangArray, setSelectedDateRang);
      endDate = moment(maxEndDate).format('YYYY-MM-DD');
      setEndDate(endDate);
    }
  }, [selectAccount, consent, selectMonth]);
  useEffect(() => {
    let dataArray = [];
    let userData = getUserData();

    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    setFilletElement(dataArray);
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    setDateToValueData(dateToValue);
  }, [selectedDateRang, endDate]);
  useEffect(() => {
    const maxObject =
      consent &&
      consent.length &&
      consent.reduce((max, currentObject) => {
        if (
          currentObject.id > max.id &&
          currentObject?.dashboardAvailable &&
          currentObject?.consent?.data?.products.filter(
            (x) => x.code === 'affordability_full'
          ).length
        ) {
          return currentObject;
        }
        return max;
      }, consent[0]);
    if (maxObject && selectAccount !== 'all' && !selectAccount) {
      setSelectAccount(maxObject?.accountNumber);
      setSelectedAccountType(maxObject?.accountType);

      if (bankList.length) {
        const selectedIcon = bankList.find(
          (i) => maxObject?.bankName === i.bankName
        );
        setSelectedBankLogo(selectedIcon?.icon);
      }
    }
  }, [consent, bankList]);
  useEffect(async () => {
    let allProducts = [];
    setLoader(true);
    let endDate = moment().format('YYYY-MM-DD');
    let accountNumberList = [];
    let hasAffordabilityAccess = false;
    await GetAxiosInstance(
      `orchestrator/get-consent`,
      'get',
      {},
      { 'journey-type': 'Dashboard' }
    ).then((res) => {
      if (res.data?.codes === 200) {
        if (res.data?.data?.consents.length) {
          setConsents(res?.data?.data?.consents);
          res.data?.data?.consents.map((v) => {
            if (
              v.consent.data.products.filter((item) => item.code === 'kyc')
                .length
            ) {
              if (v?.accountNumber) {
                accountNumberList.push({
                  title: `XXXXXX${v.accountNumber.slice(-4)}`,
                  value: v.accountNumber,
                  icon: bankList.find((i) => i.bankName === v.bankName)?.icon,
                  bankName: bankList.find((i) => i.bankName === v.bankName)
                    ?.bankName,
                });
              }
            } else {
              hasAffordabilityAccess = true;
            }
          });
          if (!accountNumberList.length) {
            navigator('/poof-of-funds');
          }

          let selectedProduct = res?.data?.data?.consent?.find(
            (item) => item.accountNumber === selectAccount
          );
          if (selectedProduct && selectAccount?.length) {
            endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
              'YYYY-MM-DD'
            );
          } else {
            let dateRangArray = res.data.data?.consents.map((v) => {
              return {
                startDate: v.consent?.data?.dateFrom,
                endDate: v.consent?.data?.dateTo,
              };
            });
            let allEndDate = res.data?.data?.consents?.map(
              (v) => v.consent?.data?.dateTo
            );
            let maxEndDate =
              allEndDate.length &&
              allEndDate.reduce(function (a, b) {
                return a > b ? a : b;
              });

            endDate = moment(maxEndDate).format('YYYY-MM-DD');
          }
          setAccountsNumberList(accountNumberList);
          res.data?.data?.consents?.forEach((v) => {
            if (
              v?.consent?.data?.products.length &&
              v?.consent?.data?.products &&
              v?.consent?.data?.accountNumber ===
                (selectAccount || accountNumberList[0]?.value)
            ) {
              v?.consent?.data?.products.forEach((i) => {
                if (i.group === 'statement') {
                  i.months.forEach((j) => {
                    let obj = {
                      accountNumber: v.accountNumber,
                      statementMonth: j,
                    };

                    allProducts.push(obj);
                  });
                }
              });
            }
          });
          setStatementAccessData(allProducts);
        }

        setConsent(res.data?.data?.consents);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      } else {
        setLoader(false);
        toast.error(
          res?.response?.data?.message || res.message || 'Something went wrong!'
        );
      }
    });
  }, [selectAccount]);
  return (
    <>
      <DashboardUINav dashboardData={dashboardData} consent={consent} />

      <PageWrapper>
        <>
          {loader ? (
            <Loader />
          ) : (
            <>
              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: { lg: 5, xs: 15 } }}
              >
                <Box sx={{ flexGrow: 1 }} />

                <Box sx={{ flexGrow: 1, mt: { sm: 0, xs: 5 }, mr: 1.5 }}>
                  <Grid container gap={2} justifyContent="flex-end">
                    <Grid item md={2.5} xs={12}>
                      <Typography variant="Body_medium_16" color="orange.main">
                        Account Number
                      </Typography>
                      {console.log('accountsNumberList', accountsNumberList)}
                      <Autocomplete
                        id="select-business-partner"
                        options={accountsNumberList || []}
                        disableClearable
                        getOptionLabel={(option) => option.title || ''}
                        PaperComponent={CustomPaper}
                        value={
                          accountsNumberList?.find(
                            (item) => item.value === selectAccount
                          ) || ''
                        }
                        renderOption={(props, option) => (
                          <Box
                            {...props}
                            component="li"
                            key={props['data-option-index']}
                            name={option.bankName}
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          >
                            <img
                              loading="lazy"
                              name={option.bankName}
                              width="35"
                              style={{ borderRadius: '50%' }}
                              src={option.icon}
                              alt=""
                            />
                            {option.title}
                          </Box>
                        )}
                        renderInput={(params, option) => (
                          <TextField
                            variant="filled"
                            id="filled-hidden-label-normal"
                            placeholder="Select..."
                            inputProps={{ 'aria-label': 'Without label' }}
                            hiddenLabel
                            fullWidth
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  {selectedBankLogo && (
                                    <Box
                                      component="img"
                                      src={selectedBankLogo}
                                      alt=""
                                      sx={{
                                        width: '35px',
                                        height: '35px',
                                        borderRadius: '50%',
                                      }}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        onChange={(e, value) => {
                          setSelectAccount(value?.value || null);
                          setSelectedBankLogo(value?.icon);
                        }}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Typography variant="Body_medium_16" color="orange.main">
                        Filter Period
                      </Typography>
                      <Autocomplete
                        id="select-business-partner"
                        options={[...filletElement, ...selectedDateRang]}
                        getOptionLabel={(option) => {
                          if (
                            moment(option?.title).format('MMMM - YYYY') !==
                            'Invalid date'
                          ) {
                            return moment(option?.title).format('MMMM - YYYY');
                          } else {
                            return option?.title;
                          }
                        }}
                        PaperComponent={CustomPaper}
                        value={
                          selectMonth
                            ? [...filletElement, ...selectedDateRang].find(
                                (item) => {
                                  if (selectedEndDate) {
                                    return item.value === selectedEndDate;
                                  } else {
                                    return item.value === selectMonth;
                                  }
                                }
                              )
                            : filletElement[filletElement.length - 1]
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            id="filled-hidden-label-normal"
                            placeholder="Select..."
                            inputProps={{ 'aria-label': 'Without label' }}
                            hiddenLabel
                            fullWidth
                            {...params}
                          />
                        )}
                        onChange={(e, newData) => {
                          setSelectMonth(newData?.value);
                          setSelectedEndDate(newData?.value);
                          // if (e.target.getInnerHTML()) {
                          //   let selected = filletElement.find(
                          //     (item) => item.title === e.target.getInnerHTML()
                          //   );
                          //   setSelectMonth(selected.value);
                          // }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
              <Grid container my={4} spacing={2}>
                {(
                  <Grid item md={6} xs={12}>
                    <MainCardStyled
                      sx={{
                        height: '100%',
                      }}
                    >
                      <Box>
                        <Typography variant="Body_medium_16">
                          CAC Registration
                        </Typography>
                      </Box>

                      <Stack m={2}>
                        <ul>
                          <ListForBusiness>
                            Company Name:- {companyDetailData?.approvedName}{' '}
                          </ListForBusiness>
                          <ListForBusiness>
                            Nature Of Business:-{' '}
                            {companyDetailData?.natureOfBusinessName}
                          </ListForBusiness>
                          <ListForBusiness>
                            Company Address:- {companyDetailData?.address},
                            {companyDetailData?.city},{companyDetailData?.state}{' '}
                          </ListForBusiness>
                          <ListForBusiness>
                            Company Number:- {companyDetailData?.rcNumber}{' '}
                          </ListForBusiness>
                          <ListForBusiness>
                            Company Status:-{' '}
                            {companyDetailData?.active ? 'Active' : 'Inactive'}{' '}
                          </ListForBusiness>
                          <ListForBusiness>
                            Date Of Creation:-{' '}
                            {companyDetailData?.registrationDate &&
                              moment(
                                companyDetailData?.registrationDate
                              ).format('YYYY-MM-DD')}{' '}
                          </ListForBusiness>
                          <ListForBusiness>No Of Directors :- </ListForBusiness>
                          <ListForBusiness>PEP List :- </ListForBusiness>
                          <ListForBusiness>Filing History :- </ListForBusiness>
                          <ListForBusiness>
                            Other companies directors are linked to :-{' '}
                          </ListForBusiness>
                          <ListForBusiness>Debt owed :- </ListForBusiness>
                          <ListForBusiness>
                            Capacity to borrow :-{' '}
                          </ListForBusiness>
                        </ul>
                      </Stack>
                    </MainCardStyled>
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  <MainCardStyled
                    sx={{
                      height: '100%',
                      // height: { xs: "auto", md: "480px", sm: "600px" },
                    }}
                  >
                    <Box>
                      <Typography variant="Body_medium_16">
                        Verification
                      </Typography>
                    </Box>

                    <Stack spacing={0.5} mt={4}>
                      <Box display={'flex'} flexDirection={'column'} ml={4}>
                        <FormControlLabel
                          name={''}
                          control={<CustomizedCheckbox />}
                          label={'Bank account verification'}
                          labelPlacement="end"
                          checked={isCheckVerification?.bankVerification}
                          sx={{
                            ml: 0,
                            gap: 2,
                          }}
                        />

                        <FormControlLabel
                          name={''}
                          control={<CustomizedCheckbox />}
                          label={'Customer Verification'}
                          checked={isCheckVerification?.customerVerification}
                          labelPlacement="end"
                          sx={{
                            ml: 0,
                            gap: 2,
                          }}
                        />

                        <FormControlLabel
                          name={''}
                          control={<CustomizedCheckbox />}
                          label={'Address Verification'}
                          checked={isCheckVerification?.addressVerification}
                          labelPlacement="end"
                          sx={{
                            ml: 0,
                            gap: 2,
                          }}
                        />
                      </Box>

                      {/* <FormControlLabel
                    name={""}
                    control={<CustomizedCheckbox />}
                    label={"Source of income"}
                    checked={isCheckVerification?.addressVerification}
                    labelPlacement="end"
                    sx={{
                      ml: 4,
                      gap: 2,
                    }}
                  /> */}
                    </Stack>
                  </MainCardStyled>
                </Grid>

                <Grid item md={6} xs={12}>
                  <MainCardStyled
                    sx={{
                      height: '100%',
                    }}
                  >
                    <Box>
                      <Typography variant="Body_medium_16">
                        Risk Insights
                      </Typography>
                    </Box>
                    <Stack m={2}>
                      <ul>
                        <ListForBusiness>Sanctioned List :- </ListForBusiness>
                        <Box
                          ml={2}
                          mb={2}
                          display="flex"
                          flexDirection="column"
                        >
                          <span>
                            a. Transactions to Sanctioned Country List
                          </span>
                          <span>b. Transactions to High-risk countries</span>
                        </Box>
                        <ListForBusiness>Other Risks :- </ListForBusiness>
                      </ul>
                    </Stack>

                    {/* <Box mt={2}>
                  <Typography variant="Body_medium_16">
                    Source of income
                  </Typography>
                </Box>

                <Stack spacing={0.5} mt={2}>
                  <FormControlLabel
                    name={""}
                    control={<CustomizedCheckbox />}
                    label={"Salary"}
                    labelPlacement="end"
                    sx={{
                      ml: 0,
                      gap: 2,
                    }}
                  />

                  <FormControlLabel
                    name={""}
                    control={<CustomizedCheckbox />}
                    label={"Dividends"}
                    labelPlacement="end"
                    sx={{
                      ml: 0,
                      gap: 2,
                    }}
                  />

                  <FormControlLabel
                    name={""}
                    control={<CustomizedCheckbox />}
                    label={"Business income"}
                    labelPlacement="end"
                    sx={{
                      ml: 0,
                      gap: 2,
                    }}
                  />

                  <FormControlLabel
                    name={""}
                    control={<CustomizedCheckbox />}
                    label={"Investments"}
                    labelPlacement="end"
                    sx={{
                      ml: 0,
                      gap: 2,
                    }}
                  />

                  <FormControlLabel
                    name={""}
                    control={<CustomizedCheckbox />}
                    label={"Others"}
                    labelPlacement="end"
                    sx={{
                      ml: 0,
                      gap: 2,
                    }}
                  />
                </Stack> */}
                  </MainCardStyled>
                </Grid>

                <Grid item md={companyDetailData ? 6 : 12} xs={12}>
                  <MainCardStyled
                    sx={{
                      height: '100%',
                    }}
                  >
                    {/* <Box>
                  <Typography variant="Body_medium_16">
                    Financial Metrics
                  </Typography>
                </Box> */}

                    <Box
                      mt={4}
                      onClick={() => setFinancialMatrics(!financialMatrics)}
                      aria-expanded={financialMatrics}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Financial Metrics
                      </Typography>

                      <ExpandMore
                        expand={financialMatrics}
                        onClick={() => setFinancialMatrics(!financialMatrics)}
                        aria-expanded={financialMatrics}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>
                    <Collapse
                      in={financialMatrics}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>Date</TableCellHead>
                              <TableCellHead>
                                Monthly Recurring Revenue (MRR)
                              </TableCellHead>
                              <TableCellHead>
                                MMR Growth Rate (MoM)
                              </TableCellHead>
                              <TableCellHead>Monthly Burn Rate</TableCellHead>
                              <TableCellHead>
                                Monthly Salary Payments
                              </TableCellHead>
                              <TableCellHead>
                                No of unique transactions
                              </TableCellHead>
                              <TableCellHead>
                                Payment to Directors
                              </TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {metrics && metrics.length ? (
                              <>
                                {metrics.map((item) => (
                                  <TableRow hover key={''}>
                                    <StyledTableCellData>
                                      {item?.month &&
                                        moment(item?.month).format('MMM-YY')}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {' '}
                                      ₦{' '}
                                      {(item?.revenue || 0).toLocaleString(
                                        undefined,
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {(item?.growth_rate || 0).toLocaleString(
                                        undefined,
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      )}{' '}
                                      %
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      ₦{' '}
                                      {(item?.burn_rate || 0).toLocaleString(
                                        undefined,
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </StyledTableCellData>
                                    <StyledTableCellData></StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.unique_customer_number}
                                    </StyledTableCellData>
                                    <StyledTableCellData></StyledTableCellData>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>
                    <Box
                      mt={4}
                      onClick={() => setTopInBound(!topInBound)}
                      aria-expanded={topInBound}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Top 5 Inflows
                      </Typography>

                      <ExpandMore
                        expand={topInBound}
                        onClick={() => setTopInBound(!topInBound)}
                        aria-expanded={topInBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                    <Collapse in={topInBound} timeout="auto" unmountOnExit>
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>Sr No.</TableCellHead>
                              <TableCellHead align="start">
                                INBOUND/OUTBOUND
                              </TableCellHead>
                              <TableCellHead align="start">
                                Description
                              </TableCellHead>
                              <TableCellHead align="start">Date</TableCellHead>
                              <TableCellHead align="start">
                                Amount
                              </TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topTransaction &&
                            topTransaction?.inFlow?.length ? (
                              <>
                                {topTransaction?.inFlow.map((item, index) => (
                                  <TableRow>
                                    <StyledTableCellData>
                                      {index + 1}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      INBOUND
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.description}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.date &&
                                        moment(item?.date).format(
                                          'DD-MMM-YYYY'
                                        )}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      ₦ {item?.amount}
                                    </StyledTableCellData>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>

                    <Box
                      mt={4}
                      onClick={() => setTopOutBound(!topOutBound)}
                      aria-expanded={topOutBound}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Top 5 Outflows
                      </Typography>

                      <ExpandMore
                        expand={topOutBound}
                        onClick={() => setTopOutBound(!topOutBound)}
                        aria-expanded={topOutBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                    <Collapse in={topOutBound} timeout="auto" unmountOnExit>
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>Sr No.</TableCellHead>
                              <TableCellHead align="start">
                                INBOUND/OUTBOUND
                              </TableCellHead>
                              <TableCellHead align="start">
                                Description
                              </TableCellHead>
                              <TableCellHead align="start">Date</TableCellHead>
                              <TableCellHead align="start">
                                Amount
                              </TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topTransaction &&
                            topTransaction?.outFlow?.length ? (
                              <>
                                {topTransaction?.outFlow.map((item, index) => (
                                  <TableRow>
                                    <StyledTableCellData>
                                      {index + 1}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      OUTBOUND
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.description}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.date &&
                                        moment(item?.date).format(
                                          'DD-MMM-YYYY'
                                        )}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      ₦ {item?.amount}
                                    </StyledTableCellData>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>

                    <Box
                      mt={4}
                      onClick={() => setBottomInBound(!bottomInBound)}
                      aria-expanded={bottomInBound}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Bottom 5 Inflows
                      </Typography>

                      <ExpandMore
                        expand={bottomInBound}
                        onClick={() => setBottomInBound(!bottomInBound)}
                        aria-expanded={bottomInBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                    <Collapse in={bottomInBound} timeout="auto" unmountOnExit>
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>Sr No.</TableCellHead>
                              <TableCellHead align="start">
                                INBOUND/OUTBOUND
                              </TableCellHead>
                              <TableCellHead align="start">
                                Description
                              </TableCellHead>
                              <TableCellHead align="start">Date</TableCellHead>
                              <TableCellHead align="start">
                                Amount
                              </TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bottomTransection &&
                            bottomTransection?.inFlow?.length ? (
                              <>
                                {bottomTransection?.inFlow.map(
                                  (item, index) => (
                                    <TableRow>
                                      <StyledTableCellData>
                                        {index + 1}
                                      </StyledTableCellData>
                                      <StyledTableCellData>
                                        OUTBOUND
                                      </StyledTableCellData>
                                      <StyledTableCellData>
                                        {item?.description}
                                      </StyledTableCellData>
                                      <StyledTableCellData>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            'DD-MMM-YYYY'
                                          )}
                                      </StyledTableCellData>
                                      <StyledTableCellData>
                                        ₦ {item?.amount}
                                      </StyledTableCellData>
                                    </TableRow>
                                  )
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>

                    <Box
                      mt={4}
                      onClick={() => setBottomOutBound(!bottomOutBound)}
                      aria-expanded={bottomOutBound}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Bottom 5 outflows
                      </Typography>

                      <ExpandMore
                        expand={bottomOutBound}
                        onClick={() => setBottomOutBound(!bottomOutBound)}
                        aria-expanded={bottomOutBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                    <Collapse in={bottomOutBound} timeout="auto" unmountOnExit>
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>Sr No.</TableCellHead>
                              <TableCellHead align="start">
                                INBOUND/OUTBOUND
                              </TableCellHead>
                              <TableCellHead align="start">
                                Description
                              </TableCellHead>
                              <TableCellHead align="start">Date</TableCellHead>
                              <TableCellHead align="start">
                                Amount
                              </TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bottomTransection &&
                            bottomTransection?.outFlow?.length ? (
                              <>
                                {bottomTransection?.outFlow.map(
                                  (item, index) => (
                                    <TableRow>
                                      <StyledTableCellData>
                                        {index + 1}
                                      </StyledTableCellData>
                                      <StyledTableCellData>
                                        INBOUND
                                      </StyledTableCellData>
                                      <StyledTableCellData>
                                        {item?.description}
                                      </StyledTableCellData>
                                      <StyledTableCellData>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            'DD-MMM-YYYY'
                                          )}
                                      </StyledTableCellData>
                                      <StyledTableCellData>
                                        ₦ {item?.amount}
                                      </StyledTableCellData>
                                    </TableRow>
                                  )
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>

                    <Box
                      mt={4}
                      onClick={() =>
                        setTop5InBoundPaymentSME(!top5InBoundPaymentSME)
                      }
                      aria-expanded={top5InBoundPaymentSME}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Top 5 Inbound Small Business Payments
                      </Typography>

                      <ExpandMore
                        expand={top5InBoundPaymentSME}
                        onClick={() =>
                          setTop5InBoundPaymentSME(!top5InBoundPaymentSME)
                        }
                        aria-expanded={top5InBoundPaymentSME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                    <Collapse
                      in={top5InBoundPaymentSME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>Sr No.</TableCellHead>
                              <TableCellHead align="start">
                                INBOUND/OUTBOUND
                              </TableCellHead>
                              <TableCellHead align="start">
                                Description
                              </TableCellHead>
                              <TableCellHead align="start">Date</TableCellHead>
                              <TableCellHead align="start">SME</TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topPaymentSME && topPaymentSME?.inFlow?.length ? (
                              <>
                                {topPaymentSME?.inFlow.map((item, index) => (
                                  <TableRow>
                                    <StyledTableCellData>
                                      {index + 1}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      INBOUND
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.description}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.date &&
                                        moment(item?.date).format(
                                          'DD-MMM-YYYY'
                                        )}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      ₦ {item?.amount}
                                    </StyledTableCellData>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>

                    <Box
                      mt={4}
                      onClick={() =>
                        setTop5OutBoundPaymentSME(!top5OutBoundPaymentSME)
                      }
                      aria-expanded={top5OutBoundPaymentSME}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Top 5 Outbound Small Business Payments
                      </Typography>

                      <ExpandMore
                        expand={top5OutBoundPaymentSME}
                        onClick={() =>
                          setTop5OutBoundPaymentSME(!top5OutBoundPaymentSME)
                        }
                        aria-expanded={top5OutBoundPaymentSME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                    <Collapse
                      in={top5OutBoundPaymentSME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>5</TableCellHead>
                              <TableCellHead align="start">
                                INBOUND/OUTBOUND
                              </TableCellHead>
                              <TableCellHead align="start">
                                Description
                              </TableCellHead>
                              <TableCellHead align="start">Date</TableCellHead>
                              <TableCellHead align="start">SME</TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topPaymentSME && topPaymentSME?.outFlow?.length ? (
                              <>
                                {topPaymentSME?.outFlow.map((item, index) => (
                                  <TableRow>
                                    <StyledTableCellData>
                                      {index + 1}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      INBOUND
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.description}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.date &&
                                        moment(item?.date).format(
                                          'DD-MMM-YYYY'
                                        )}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      ₦ {item?.amount}
                                    </StyledTableCellData>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>

                    <Box
                      mt={4}
                      onClick={() =>
                        setTop5InBoundPaymentLME(!top5InBoundPaymentLME)
                      }
                      aria-expanded={top5InBoundPaymentLME}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Top 5 Inbound Large Payments
                      </Typography>

                      <ExpandMore
                        expand={top5InBoundPaymentLME}
                        onClick={() =>
                          setTop5InBoundPaymentLME(!top5InBoundPaymentLME)
                        }
                        aria-expanded={top5InBoundPaymentLME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                    <Collapse
                      in={top5InBoundPaymentLME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>Sr No.</TableCellHead>
                              <TableCellHead align="start">
                                INBOUND/OUTBOUND
                              </TableCellHead>
                              <TableCellHead align="start">
                                Description
                              </TableCellHead>
                              <TableCellHead align="start">Date</TableCellHead>
                              <TableCellHead align="start">LME</TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topPaymentLME && topPaymentLME?.inFlow?.length ? (
                              <>
                                {topPaymentLME?.inFlow.map((item, index) => (
                                  <TableRow>
                                    <StyledTableCellData>
                                      {index + 1}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      INBOUND
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.description}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.date &&
                                        moment(item?.date).format(
                                          'DD-MMM-YYYY'
                                        )}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      ₦ {item?.amount}
                                    </StyledTableCellData>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>

                    <Box
                      mt={4}
                      onClick={() =>
                        setTop5OutBoundPaymentLME(!top5OutBoundPaymentLME)
                      }
                      aria-expanded={top5OutBoundPaymentLME}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <Typography variant="Body_medium_16">
                        Top 5 Outbound Large Business Payments
                      </Typography>

                      <ExpandMore
                        expand={top5OutBoundPaymentLME}
                        onClick={() =>
                          setTop5OutBoundPaymentLME(!top5OutBoundPaymentLME)
                        }
                        aria-expanded={top5OutBoundPaymentLME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                    <Collapse
                      in={top5OutBoundPaymentLME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableWrapper>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: 350,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCellHead>Sr No.</TableCellHead>
                              <TableCellHead align="start">
                                INBOUND/OUTBOUND
                              </TableCellHead>
                              <TableCellHead align="start">
                                Description
                              </TableCellHead>
                              <TableCellHead align="start">Date</TableCellHead>
                              <TableCellHead align="start">LME</TableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topPaymentLME && topPaymentLME?.outFlow?.length ? (
                              <>
                                {topPaymentLME?.outFlow.map((item, index) => (
                                  <TableRow>
                                    <StyledTableCellData>
                                      {index + 1}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      INBOUND
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.description}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      {item?.date &&
                                        moment(item?.date).format(
                                          'DD-MMM-YYYY'
                                        )}
                                    </StyledTableCellData>
                                    <StyledTableCellData>
                                      ₦ {item?.amount}
                                    </StyledTableCellData>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Collapse>

                    {/* <TableWrapper>
                      <Table
                        stickyHeader
                        sx={{
                          minWidth: 350,
                        }}
                      >
                        <TableRow>
                          <TableCellHead>Bottom 5 Transaction</TableCellHead>
                          <TableCellHead align="start">
                            INBOUND/OUTBOUND
                          </TableCellHead>
                          <TableCellHead align="start">
                            Description
                          </TableCellHead>
                          <TableCellHead align="start">Date</TableCellHead>
                          <TableCellHead align="start">Amount</TableCellHead>
                        </TableRow>
                        <TableBody>
                          {bottomTransection &&
                          bottomTransection?.inFlow?.length ? (
                            <>
                              {bottomTransection?.inFlow.map((item, index) => (
                                <TableRow>
                                  <StyledTableCellData>
                                    {index + 1}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    INBOUND
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.description}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    ₦ {item?.amount}
                                  </StyledTableCellData>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                          {bottomTransection &&
                          bottomTransection?.outFlow.length ? (
                            <>
                              {bottomTransection?.outFlow.map((item, index) => (
                                <TableRow>
                                  <StyledTableCellData>
                                    {index + 1}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    OUTBOUND
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.description}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    ₦ {item?.amount}
                                  </StyledTableCellData>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </TableBody>
                      </Table>
                    </TableWrapper>
             
                    <TableWrapper>
                      <Table
                        stickyHeader
                        sx={{
                          minWidth: 350,
                        }}
                      >
                        <TableRow>
                          <TableCellHead>Top 5 Payments</TableCellHead>
                          <TableCellHead align="start">
                            INBOUND/OUTBOUND
                          </TableCellHead>
                          <TableCellHead align="start">
                            Description
                          </TableCellHead>
                          <TableCellHead align="start">Date</TableCellHead>
                          <TableCellHead>SME</TableCellHead>
                        </TableRow>
                        <TableBody>
                          {topPaymentLME && topPaymentLME?.inFlow?.length ? (
                            <>
                              {topPaymentLME?.inFlow.map((item, index) => (
                                <TableRow>
                                  <StyledTableCellData>
                                    {index + 1}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    INBOUND
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.description}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    ₦ {item?.amount}
                                  </StyledTableCellData>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                          {topPaymentLME && topPaymentLME?.outFlow.length ? (
                            <>
                              {topPaymentLME?.outFlow.map((item, index) => (
                                <TableRow>
                                  <StyledTableCellData>
                                    {index + 1}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    OUTBOUND
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.description}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    ₦ {item?.amount}
                                  </StyledTableCellData>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </TableBody>
                      </Table>
                    </TableWrapper>
      
                    <TableWrapper>
                      <Table
                        stickyHeader
                        sx={{
                          minWidth: 350,
                        }}
                      >
                        <TableRow>
                          <TableCellHead>Top 5 Payments</TableCellHead>
                          <TableCellHead align="start">
                            INBOUND/OUTBOUND
                          </TableCellHead>
                          <TableCellHead align="start">
                            Description
                          </TableCellHead>
                          <TableCellHead align="start">Date</TableCellHead>
                          <TableCellHead>LC</TableCellHead>
                        </TableRow>
                        <TableBody>
                          {topPaymentLME && topPaymentLME?.inFlow?.length ? (
                            <>
                              {topPaymentLME?.inFlow.map((item, index) => (
                                <TableRow>
                                  <StyledTableCellData>
                                    {index + 1}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    INBOUND
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.description}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    ₦ {item?.amount}
                                  </StyledTableCellData>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                          {topPaymentLME && topPaymentLME?.outFlow.length ? (
                            <>
                              {topPaymentLME?.outFlow.map((item, index) => (
                                <TableRow>
                                  <StyledTableCellData>
                                    {index + 1}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    OUTBOUND
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.description}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    ₦ {item?.amount}
                                  </StyledTableCellData>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </TableBody>
                      </Table>
                    </TableWrapper> */}
                  </MainCardStyled>
                </Grid>

                <Grid item xs={12}>
                  <MainCardStyled>
                    <TableWrapper>
                      <Table
                        stickyHeader
                        sx={{
                          minWidth: 350,
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCellHead>Source of Income</TableCellHead>
                            <TableCellHead>Date</TableCellHead>
                            <TableCellHead>Description</TableCellHead>
                            <TableCellHead>Category</TableCellHead>
                            <TableCellHead>Amount</TableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transection.length ? (
                            transection.filter(item => item?.description !== 'Total').map((item) => (
                              <>
                                <TableRow hover key={''}>
                                  <StyledTableCellData>
                                    {item?.detail}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.description !== 'Total' &&
                                      moment(item?.date).format('DD-MM-YYYY')}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.description}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                    {item?.category}
                                  </StyledTableCellData>
                                  <StyledTableCellData>
                                  ₦{' '}
                                    {(item?.amount || 0).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      })}
                                  </StyledTableCellData>
                                </TableRow>
                              </>
                            ))
                          ) : (
                            <></>
                          )}
                          <TableRow hover>
                           <StyledTableCellData colSpan={4} align="right">
                            <strong>Total Amount:</strong>
                           </StyledTableCellData>
                          <StyledTableCellData>
                           ₦{' '}
                            {transection.filter(item => item?.description !== 'Total').reduce((acc, item) => acc + (item?.amount || 0), 0).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            })}
                          </StyledTableCellData>
                        </TableRow>
                        </TableBody>
                      </Table>
                    </TableWrapper>
                  </MainCardStyled>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </PageWrapper>
    </>
  );
};
function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange?.forEach((v) => {
    var startDate = moment(v.startDate);
    var endDate = moment(v.endDate);
    if (endDate.isBefore(startDate)) {
      return;
    }
    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

      let diff = moment(endDate).diff(moment(lastDate), 'days');
      if (diff < 0) {
        lastDate = moment(endDate).format('YYYY-MM-DD');
      }

      result.push(lastDate);
      startDate.add(1, 'month');
    }
    result
      .sort(
        (a, b) =>
          new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')
      )
      .reverse();
    function filterUniqueDates(data) {
      const lookup = new Set();
      return data.filter((date) => {
        const serialised = new Date(date).getTime();

        if (lookup.has(serialised)) {
          return false;
        } else {
          lookup.add(serialised);
          return true;
        }
      });
    }
    let uniqueDates = filterUniqueDates(result);
    uniqueDates =
      uniqueDates.length &&
      uniqueDates.map((i) => {
        return {
          title: i,
          value: i,
        };
      });
    setSelectedDateRang(uniqueDates);
  });
}

export default IncomeAndJobVerification;
