import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import ScrollToTop from "./components/common/ScrollToTop";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "react-phone-input-2/lib/style.css";
import Toast from "./components/common/Toast";
import TagManager from "react-gtm-module";
import SessionExpiredDialog from "./components/common/InactiveHandler";
import { setSessionExpiredHandler } from "./components/common/api";

function App() {
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-Z4DNT6CX7R",
    };
    TagManager.initialize(tagManagerArgs);

    // Set session expiration handler globally
    setSessionExpiredHandler(setSessionExpired);
  }, []);

  // Function to handle session expiration
  const handleSessionExpired = () => {
    localStorage.clear(); // Clear stored user data
    setSessionExpired(false); // Close popup
    window.location.href = "/login"; // Redirect to login
  };

  return (
    <BrowserRouter>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <Router />
        <Toast />
        
        {/* Session Expired Popup */}
        <SessionExpiredDialog open={sessionExpired} onClose={handleSessionExpired} />
      </ThemeConfig>
    </BrowserRouter>
  );
}

export default App;
