import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import GetAxiosInstance from "../../components/common/api";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { BoxWrapper } from "../../components/common/style";
import {
  TableCellData,
  TableCellDataDashboard,
  TableCellHead,
  TableRows,
} from "../../components/common/formStyle/Table";
import {
  ContentStyle,
  FormInfoBox,
  FormInputWrapper,
  FormStyle,
} from "../Auth/AuthStyle";
import {
  MainCardBox,
  SubCardTopInfo,
  SubTableCardWeapper,
  SubTableCardWeapperNew,
  TableTitleTypography,
} from "../dashboard/DashboardStyle";
import moment from "moment";
import { EXTERNAL_APP_CLIENT_SECRET, EXTERNAL_APP_CLIENT_ID, EXTERNAL_APP_PUBLIC_KEY } from "./../../consts/app.const"
var EDoc;
const token =
  "Bearer " + EXTERNAL_APP_CLIENT_SECRET;
const ExternalDemo = () => {
  const theme = useTheme();
  const [emil, setEmail] = useState();
  const [statementDuration, setStatementDuration] = useState();
  const [transction, setTransction] = useState();
  const [loading, setLoading] = useState();
  const handleClick = async () => {
    setLoading(true);
    await GetAxiosInstance(
      'external/consent/initialize',
      'post',
      {
        email: emil,
        redirection_url: '',
        statementDuration,
      },
      {
        'client-id': EXTERNAL_APP_CLIENT_ID,
      },
      token
    ).then((res, err) => {
      var handler = window.EDoc.setup({
        key: EXTERNAL_APP_PUBLIC_KEY, // Replace with your public key
        consent_id: res?.data?.data?.consentId, // Replace with a conesnt id you generated
        onSuccess: function (response) {
          //this happens after the payment is completed successfully
          setTransction(response?.eventData?.transactions);
          console.log('EDoc Response', response);
          // Make an AJAX call to your server with the reference to verify the transaction
        },
        onError: function (error) {
          console.log('EDoc Error', error);
        },
        onClose: function () {
          // alert("Transaction was not completed, window closed.");
        },
      });
      handler.openWindow();
    });
    setLoading(false);
  };
  return (
    <Container disableGutters>
      <BoxWrapper style={{ marginTop: "10px" }}>
        <Container>
          <ContentStyle>
            <FormStyle component="form" autoComplete="off">
              <Stack>
                <FormInfoBox>
                  <Typography variant="Body_semibold_24">
                    Please Enter Email
                  </Typography>
                </FormInfoBox>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <FormInputWrapper sx={{ gap: 1 }}>
                      <TextField
                        variant="filled"
                        type="email"
                        placeholder="Email"
                        id="filled-hidden-label-normal"
                        fullWidth
                        inputProps={{ "aria-label": "Without label" }}
                        hiddenLabel
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormInputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputWrapper sx={{ gap: 1 }}>
                      <TextField
                        variant="filled"
                        type="number"
                        placeholder="Statement Duration (in Months)"
                        id="filled-hidden-label-normal"
                        fullWidth
                        inputProps={{ "aria-label": "Without label" }}
                        hiddenLabel
                        onChange={(e) => setStatementDuration(e.target.value)}
                      />
                    </FormInputWrapper>
                  </Grid>
                  <Grid>
                    <AnimateButton>
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{
                          width: { xs: "auto", sm: "215px" },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={handleClick}
                        loadingIndicator={
                          <CircularProgress
                            style={{
                              padding: "60px",
                              color: theme.palette.orange.main,
                            }}
                          />
                        }
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </Stack>
            </FormStyle>
          </ContentStyle>
          <Grid item xs={12} mt={5}>
            <MainCardBox>
              <SubTableCardWeapperNew>
                <SubCardTopInfo my={2}>
                  <TableTitleTypography color="orange.main">
                    Transactions
                  </TableTitleTypography>
                  <TableContainer sx={{ py: 2 }}>
                    <Table
                      sx={{
                        minWidth: 350,
                      }}
                    >
                      <TableHead>
                        <TableRows>
                          <TableCellHead>Credit Type</TableCellHead>
                          <TableCellHead>Transaction Date</TableCellHead>
                          <TableCellHead>Transaction Information</TableCellHead>
                          <TableCellHead>Amount</TableCellHead>
                        </TableRows>
                      </TableHead>
                      <TableBody>
                        {transction?.map((row, index) => (
                          <>
                            {" "}
                            <TableRow hover key={index}>
                              <TableCellDataDashboard>
                                {row.creditType}
                              </TableCellDataDashboard>
                              <TableCellDataDashboard>
                                {moment(row.transactionDate).format(
                                  "DD MMM YYYY"
                                )}
                              </TableCellDataDashboard>
                              <TableCellDataDashboard>
                                {row.transactionInformation}
                              </TableCellDataDashboard>
                              <TableRow hover key={index}>
                                <TableCellDataDashboard>{`₦ ${row.amount}`}</TableCellDataDashboard>
                              </TableRow>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </SubCardTopInfo>
              </SubTableCardWeapperNew>
            </MainCardBox>
          </Grid>
        </Container>
      </BoxWrapper>
    </Container>
  );
};
export default ExternalDemo;
