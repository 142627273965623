import { Navigate, useRoutes } from 'react-router-dom';
import { LoginLayout } from './components/Layout/LoginLayout';
import { MainLayout } from './components/Layout/MainLayout';
import { NoLayout } from './components/Layout/NoLayout';

import { AccountSetup } from './pages/Auth/AccountSetup';
import { EmailVerification } from './pages/Auth/EmailVerification';
import { ForgotPassword } from './pages/Auth/ForgotPassword';
import { Login } from './pages/Auth/Login';
import { PasswordSetup } from './pages/Auth/PasswordSetup';
import { Signup } from './pages/Auth/Signup';
import AccountSuccsess from './pages/Auth/SuccsessModal/AccountSuccsess';
import EmailVerificationSuccess from './pages/Auth/SuccsessModal/EmailVerificationSuccess';
import PasswordResetSuccess from './pages/Auth/SuccsessModal/PasswordResetSuccess';

import Home from './pages/Home';

import Permission from './pages/permission';
import AccountInformation from './pages/permission/AccountInformation';
import ExternalAccountInformation from './pages/permission/ExternalAccountInformation';

import BankSeleciton from './pages/bank-selection';
import ConsentSuccess from './pages/bank-selection/ConsentSuccess';
import ExternalConsentSuccess from './pages/bank-selection/ExternalConsentSuccess';

import { DashboardLayout } from './components/Layout/DashboardLayout';
import { DashboardUI } from './components/Layout/DashboardUI';
import { ExternalLayout } from './components/Layout/ExternalLayout';
import { ChangePassword } from './pages/Auth/changePassword';
import { Confirm } from './pages/Auth/changePassword/confirm';
import ResetAccount from './pages/Auth/ResetAccount';
import UnlockAccount from './pages/Auth/UnlockAccount';
import VerifyAccount from './pages/Auth/VerifyAccount';
import PendingApproval from './pages/bank-selection/PendingApproval';
import VerifyOTP from './pages/bank-selection/VerifyOTP';
import CategoryInsights from './pages/category-insights';
import ExpandList from './pages/category-insights/ExpandList';
import Dashboard from './pages/dashboard';
import Overview from './pages/dashboard-ui/Overview';
import ExternalDemo from './pages/externalDemo';
import { GuestLogin } from './pages/guest/guestLogin';
import IncomeAndJobVerification from './pages/IncomeAndJobVerification';
import SelectPartner from './pages/partner/SelectPartner';
import DirectDebit from './pages/permission/DirectDebit';
import DirectDebitSuccess from './pages/permission/DirectDebitSuccerss';
import Plans from './pages/plans';
import CancellationComplete from './pages/plans/CancellationComplete';
import CancelPlan from './pages/plans/CancelPlan';
import ConfirmCancellation from './pages/plans/ConfirmCancellation';
import ProofOfFunds from './pages/ProofOfFunds';
import RiskAndInsight from './pages/risk-and-insight';
import Statement from './pages/statement';
import { ExternalLoginLayout } from './components/Layout/ExternalLoginLayout';
import FiResult from './pages/financial-analysis/fi-result';
import FinancialInstitution from './pages/financial-analysis/financial-institution';
import Portfolio from './pages/financial-analysis/portfolio';
import Summary from './pages/financial-analysis/summary';
import { ExternalLogin } from './pages/financial-analysis/external-login';
import BreakdownOfLoans from './pages/financial-analysis/loan-break-down';
import DirectorsTable from './pages/financial-analysis/DirectorsTable';
import CashFlowAnalysisTable from './pages/cash-flow-analysis/cash-flow-analysis';
import { CashFlowAnalysisLayout } from './components/Layout/CashFlowLayout';
import CashFlowMatrixTable from './pages/cash-flow-analysis/cash-flow-matrix';

import { EmployeeDetails } from './pages/income-verification/employeeDetails';
import  IncomeVerificationOverview  from './pages/income-verification/index';
import { CRAGuestLogin } from './pages/guest/CRAGuestLogin';
import FinancialInstitutionNigeria from './pages/financial-analysis/nigeria/financial-institution-nigeria';
import BVNWaiting from './pages/permission/BVNWaiting';
import BVN from './pages/BVN';
import FiResultNg from './pages/financial-analysis/nigeria/fi-result-ng';
import PortfolioNg from './pages/financial-analysis/nigeria/portfolio-ng';
import BreakdownOfLoansNg from './pages/financial-analysis/nigeria/loan-break-down-ng';
import DirectorsTableNg from './pages/financial-analysis/nigeria/DirectorsTable-ng';
import { IncomeVerificationDetails } from './pages/income-verification/incomeVerificationDetails';
import { EmployeeVerifyEmail } from './pages/income-verification/verify-email';
import IncomeVerificationVerifySuccess from './pages/income-verification/verify-success-Model';
import { IncomeVerificationOtpVerify } from './pages/income-verification/IncomeVerificationOtpVerify';



// ----------------------------------------------------------------------

export default function Router() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   // let userData = getUserData();
  //   // if (!userData) {
  //   //   navigate("/login");
  //   // }
  // }, []);

  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <Navigate to="/login" replace /> },
        { path: '/permission', element: <Permission /> },
        { path: '/account-information', element: <AccountInformation /> },
        { path: '/direct-debit/:id', element: <DirectDebit /> },
        { path: '/bank-seleciton', element: <BankSeleciton /> },
      ],
    },
    {
      path: '/',
      element: <LoginLayout />,
      children: [
        { path: '/login', element: <Login /> },
        { path: '/signup', element: <Signup /> },
        { path: '/bvn-waiting', element: <BVNWaiting /> },
        { path: '/account-unlock', element: <UnlockAccount /> },
        { path: '/verify-email', element: <VerifyAccount /> },
        { path: '/reset-password', element: <ResetAccount /> },
        { path: '/direct-success', element: <DirectDebitSuccess /> },
        { path: '/employee-details', element: <EmployeeDetails /> },
        { path: '/account-setup', element: <AccountSetup /> },
        { path: '/password-setup', element: <PasswordSetup /> },
        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: '/email-verification', element: <EmailVerification /> },
        { path: '/change-password', element: <ChangePassword /> },
        { path: '/confirm', element: <Confirm /> },
        // Guest
        { path: '/consent-request/partner/:id', element: <CRAGuestLogin /> },
        { path: '/mandate-request/partner/:id', element: <GuestLogin /> },
        {
          path: '/income-verification/partner/:id',
          element: <EmployeeVerifyEmail />,
        },
        {
          path: '/income-verification/partner/details/:id',
          element: <IncomeVerificationDetails />,
        },
        {
          path: '/income-verification/partner/otp/:id',
          element: <IncomeVerificationOtpVerify />,
        },
        {
          path: '/income-verification/partner/success',
          element: <IncomeVerificationVerifySuccess />,
        },

        // { path: "/consent-request/external/:consentId/auth/:publicKey", element: <AppConsentAuth /> },
      ],
    },

    {
      path: '/',
      element: <NoLayout />,
      children: [
        { path: '/home', element: <Home /> },

        { path: '/consent-success', element: <ConsentSuccess /> },
        {
          path: '/consent-request/:externalAppConsentId/consent-success',
          element: <ExternalConsentSuccess />,
        },
        { path: '/cancel-plan', element: <CancelPlan /> },
        { path: '/confirm-cancellation', element: <ConfirmCancellation /> },
        { path: '/cancellation-complete', element: <CancellationComplete /> },

        { path: '/account-created', element: <AccountSuccsess /> },
        { path: '/password-reset-success', element: <PasswordResetSuccess /> },
        {
          path: '/email-verification-success',
          element: <EmailVerificationSuccess />,
        },
        {
          path: '/pending-approval',
          element: <PendingApproval />,
        },
        { path: '/verify-otp', element: <VerifyOTP /> },
        {
          path: '/consent-request/external/:externalAppConsentId/auth/:publicKey',
          element: <ExternalAccountInformation />,
        },
      ],
    },

    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/view-plans', element: <Plans /> },
        { path: '/select-partner', element: <SelectPartner /> },
      ],
    },

    {
      path: '/',
      element: <DashboardUI />,
      children: [
        {
          path: '/dashboard-overview',
          element: <Overview />,
        },
        {
          path: 'income-verification-overview',
          element: <IncomeVerificationOverview />,
        },
        {
          path: 'bvn-dashboard',
          element: <BVN />,
        },
        { path: 'risk-and-insight', element: <RiskAndInsight /> },
        { path: 'category-insights', element: <CategoryInsights /> },
        { path: 'expand-list', element: <ExpandList /> },
        { path: 'statement', element: <Statement /> },
        {
          path: 'income-and-job-verification',
          element: <IncomeAndJobVerification />,
        },
        {
          path: 'poof-of-funds',
          element: <ProofOfFunds />,
        },
        {
          path: 'external-demo',
          element: <ExternalDemo />,
        },
        // {
        //   path: "documents",
        //   element: <Documents />,
        // },
      ],
    },
    {
      path: '/',
      element: <ExternalLoginLayout />,
      children: [{ path: '/external-login', element: <ExternalLogin /> }],
    },
    {
      path: '/',
      element: <ExternalLayout />,
      children: [
        {
          path: '/financial-institution-nigeria',
          element: <FinancialInstitutionNigeria />,
        },
        { path: '/financial-result-ng', element: <FiResultNg /> },
        { path: '/financial-portfolio-ng', element: <PortfolioNg /> },
        {
          path: '/financial-loan-breakdown-ng',
          element: <BreakdownOfLoansNg />,
        },
        { path: '/financial-Board-director-ng', element: <DirectorsTableNg /> },
        { path: '/financial-institution', element: <FinancialInstitution /> },
        { path: '/financial-result', element: <FiResult /> },
        { path: '/financial-portfolio', element: <Portfolio /> },
        { path: '/financial-loan-breakdown', element: <BreakdownOfLoans /> },
        { path: '/financial-Board-director', element: <DirectorsTable /> },
        // { path: '/financial-summary', element: <Summary /> },
      ],
    },

    {
      path: '/',
      element: <CashFlowAnalysisLayout />,
      children: [
        { path: '/cash-flow-analysis', element: <CashFlowAnalysisTable /> },
        { path: '/cash-flow-matrix', element: <CashFlowMatrixTable /> },
      ],
    },

    { path: '*', element: <Navigate to="/home" replace /> },
  ]);
}
