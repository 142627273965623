import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import {
  ContentStyle,
  EmailTypography,
  FormInfoBox,
  FormStyle,
  FromWrapper,
  RootStyle,
} from '../Auth/AuthStyle';

// ----------------------------------------------------------------------

export function IncomeVerificationOtpVerify() {
  const location = useLocation();
  const { id } = useParams();
  let navigate = useNavigate();
  const { state } = useLocation();
  const theme = useTheme();

  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(30);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState(false); // State for error handling

  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    if (timer > 0) {
      setTimeout(timeOutCallback, 1000);
    }
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(30);
    }
  };

  async function handleClick() {
    if (!otp || otp.length < 6) {
      setOtpError(true);
      toast.error('Please enter a valid 6-digit OTP.');
      return;
    }
    setOtpError(false);
    setLoading(true);

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_EDOC_API_URL,
      headers: {
        Authorization: `Bearer ${location?.state?.user?.accessToken}`,
        'app-name': process.env.REACT_APP_NAME,
        'app-version': process.env.REACT_APP_VERSION,
        'journey-type': 'income-verification',
      },
    });

    try {
      const response = await axiosInstance.post(
        `orchestrator/incomeVerification/transaction/${id}`,
        { verificationCode: otp }
      );

      setLoading(false);
      if (response?.data?.codes === 200) {
        navigate('/income-verification/partner/success', { state: state });
      } else {
        toast.error(response?.data?.message || 'Something went wrong');
      }
    } catch (error) {
      setLoading(false);
      console.error('API Error:', error);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  }

  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle component="form" autoComplete="off">
            <Stack>
              <FormInfoBox>
                <Typography variant="Body_semibold_24">
                  Email verification
                </Typography>
                <EmailTypography>
                  We have sent a 6 digit OTP code to your email address (
                  {state?.email}). Kindly input to verify and continue.
                </EmailTypography>
              </FormInfoBox>

              <Grid item xs={12}>
                <OtpInput
                  value={otp}
                  onChange={(otpNumber) => setOtp(otpNumber)}
                  numInputs={6}
                  containerStyle={{ justifyContent: 'space-between' }}
                  inputStyle={{
                    width: '100%',
                    height: '50px',
                    color: 'white',
                    margin: 8,
                    backgroundColor: theme.palette.primary.light,
                    padding: '10px',
                    border: 'none',
                    borderRadius: 12,
                  }}
                  focusStyle={{
                    outline: 'none',
                    border: 'none',
                  }}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
                {otpError && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    OTP is required and must be 6 digits.
                  </Typography>
                )}
              </Grid>

              {state?.flow !== 'resetPassword' && (
                <Typography
                  variant="Body_light_14"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '30px 0 48px 0',
                    cursor: timer === 0 ? 'pointer' : 'default',
                    color: timer === 0 ? theme.palette.primary.main : 'gray',
                  }}
                  onClick={resetTimer}
                >
                  {timer > 0 ? (
                    <>Didn't receive an OTP? Resend in {timer} sec</>
                  ) : (
                    <>Resend OTP</>
                  )}
                </Typography>
              )}

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button" // Changed to button to prevent unintended form submission
                  variant="contained"
                  style={{
                    width: '100%',
                    margin: '30px 0',
                  }}
                  onClick={handleClick}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Verify
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
